import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthParticipant } from '@gal/core/models/auth-participant.model';
import { Auth } from '@gal/core/models/auth.model';
import { FlowType } from '@gal/core/models/flow-type.model';
import { AuthService } from '@gal/core/services/auth.service';
import { DialogConfirmationComponent } from '@gal/shared/components';
import { Observable, of } from 'rxjs';
import { filter, map, pairwise, startWith } from 'rxjs/operators';

@Component({
  selector: 'gal-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  selectedParticipantControl: FormControl = new FormControl(this.auth.getSelectedParticipant());
  selectedFlowTypeControl: FormControl = new FormControl(this.auth.getSelectedFlowType());
  userInfo?: Auth;

  filteredParticipant: Observable<AuthParticipant[]> = of([]);
  flowTypes: FlowType[] = [];

  constructor(private auth: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.userInfo = this.auth.userInfo;
    this.flowTypes = this.auth.flowTypes;

    setTimeout(() => {
      this.selectedParticipantControl.setValue(this.auth.getSelectedParticipant());
      this.selectedFlowTypeControl.setValue(this.auth.getSelectedFlowType());
    }, 1);

    this.selectedParticipantControl.valueChanges.subscribe(value => {
      if (typeof value === 'object' && value.id !== this.auth.getSelectedParticipant()?.id) {
        this.selectParticipant(value);
      }
    });

    this.selectedFlowTypeControl.valueChanges.subscribe(value => {
      if (value.id !== this.auth.getSelectedFlowType()?.id) {
        this.selectFlowType(value);
      }
    });

    this.auth.updateHeaderSubject.subscribe(() => {
      this.userInfo = this.auth.userInfo;
      this.selectedParticipantControl.setValue(this.auth.getSelectedParticipant());
      this.selectedFlowTypeControl.setValue(this.auth.getSelectedFlowType());
    });

    this.filteredParticipant = this.selectedParticipantControl.valueChanges.pipe(
      startWith(''),
      pairwise(),
      map(([oldvalue, newvalue]) => (typeof oldvalue === 'object' && typeof newvalue === 'string' ? '' : newvalue)),
      map(v => (typeof v === 'object' ? '' : v)),
      map(val => (!!val ? this.filterParticipant(val) : this.getUserInfo()?.participants ?? []))
    );
  }

  filterParticipant = (val: string): AuthParticipant[] =>
    this.getUserInfo()?.participants?.filter((option: AuthParticipant) => option?.nickname?.toLowerCase().includes(val.toLowerCase())) ??
    [];

  getUserInfo(): Auth | undefined {
    return this.auth.userInfo;
  }

  checkIsSelectedParticipant(): void {
    setTimeout(() => {
      if (typeof this.selectedParticipantControl.value !== 'object') {
        this.selectedParticipantControl.setValue(this.auth.getSelectedParticipant());
      }
    }, 300);
  }

  getParticipantName = (option: AuthParticipant): string => option?.nickname ?? '';

  logout() {
    DialogConfirmationComponent.open({
      title: 'Deseja realmente sair?',
      message: ''
    })
      .afterClosed()
      .pipe(filter(confirm => !!confirm))
      .subscribe(() => this.auth.logout());
  }

  private selectParticipant(participant: AuthParticipant): void {
    this.auth.selectParticipant(participant.id);
  }

  private selectFlowType(flow: FlowType): void {
    this.auth.selectFlowType(flow.id);
  }
}
