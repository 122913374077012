import { HostListener } from '@angular/core';
import { Directive, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[galIntegerOnly]'
})
export class IntegerOnlyDirective {
  constructor(private e: ElementRef<HTMLInputElement>, private control: NgControl) {}

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initalValue = this.e.nativeElement.value;
    const digits = initalValue.replace(/[^0-9]*/g, '');
    const clearedValue = !!digits ? String(Number(digits)).padStart(digits.length, '0') : '';

    this.control?.control?.setValue(clearedValue);

    if (initalValue !== clearedValue) {
      event.stopPropagation();
    }
  }
}
