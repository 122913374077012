<h1 mat-dialog-title class="new-participant-dialog-title">AVISO</h1>
<div mat-dialog-content class="new-participant-dialog-content">
  <div class="content">
    <div class="description">

      <div *ngIf="data.administrator.length === 1">
        <div>
          Sua instituição foi indicada como Administradora para o Fundo abaixo listado, a partir da respectiva data de vigência.
        </div>
        <div>
          A transferência deve ser confirmada até a data informada, caso contrário, será desconsiderada, ficando o fundo em nome do administrador atual.
        </div>
      </div>
      <div *ngIf="data.administrator.length > 1">
        <div>
          Sua instituição foi indicada como Administradora para os Fundos abaixo listados, a partir das respectivas datas de vigência.
        </div>
        <div>
          As transferências devem ser confirmadas até as datas informadas, caso contrário, serão desconsideradas, ficando os fundos em nome dos administradores atuais.
        </div>
      </div>

      <div>
        <br />
        Após confirmação, você pode atualizar o cadastro do fundo, para que as boletas sejam direcionadas corretamente.
      </div>
    </div>

    <div class="fields" *ngFor="let fund of data.administrator; let i = index">
      <div fxLayout="row" class="header-fields">
        <mat-form-field class="mat-form-field-readonly">
          <mat-label> CNPJ do Fundo </mat-label>
          <input id="dialog-new-participant-cnpj" matInput type="text" readonly [value]="fund.document | emptyField | document | emptyField"/>
        </mat-form-field>

        <mat-form-field class="mat-form-field-readonly" [style.width.%]="100">
          <mat-label> Nome do Fundo/Razão Social </mat-label>
          <input id="dialog-new-participant-name" matInput type="text" readonly [value]="fund.name"/>
        </mat-form-field>
      </div>

      <div class="fund-participants">
        <!-- Future Participants - first line on grid - BEGIN -->
        <div class="future-participant" *ngIf="fund.future_administrator; else emptyDiv">
          <div class="container-fields">
            <mat-form-field class="mb-15" class="mat-form-field-readonly">
              <mat-label>Administrador</mat-label>
              <input id="dialog-new-participant-future-administrator" matInput type="text" readonly [value]="fund.future_administrator.document | document"/>
              <mat-hint *ngIf="fund.future_administrator.id">
                {{ fund.future_administrator.name }}
                <br />
                <div class="participant-date" *ngIf="!!fund.future_administrator.date">
                  {{ fund.future_administrator.date | date: "shortDate" }}
                </div>
              </mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div class="future-participant" *ngIf="fund.future_asset_controller; else emptyDiv">
          <div class="container-fields">
            <mat-form-field class="mb-15" class="mat-form-field-readonly">
              <mat-label>Controlador do Ativo</mat-label>
              <input id="dialog-new-participant-future-asset-controller" matInput type="text" readonly [value]="fund.future_asset_controller?.document | emptyField | document | emptyField"/>
              <mat-hint *ngIf="fund.future_asset_controller?.id">
                {{ fund.future_asset_controller.name }}
                <br />
                <div class="participant-date" *ngIf="!!fund.future_asset_controller.date">
                  {{ fund.future_asset_controller.date | date: "shortDate" }}
                </div>
              </mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div class="future-participant" *ngIf="fund.future_liability_controller; else emptyDiv">
          <div class="container-fields">
            <mat-form-field class="mb-15" class="mat-form-field-readonly">
              <mat-label>Controlador do Passivo</mat-label>
              <input id="dialog-new-participant-future-liability-controller" matInput type="text" readonly [value]="fund.future_liability_controller?.document | emptyField | document | emptyField"/>
              <mat-hint *ngIf="fund.future_liability_controller?.id">
                {{ fund.future_liability_controller.name }}
                <br />
                <div class="participant-date" *ngIf="!!fund.future_liability_controller.date">
                  {{ fund.future_liability_controller.date | date: "shortDate" }}
                </div>
              </mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div class="future-participant" *ngIf="fund.future_custodian; else emptyDiv">
          <div class="container-fields">
            <mat-form-field class="mb-15" class="mat-form-field-readonly">
              <mat-label>Custodiante</mat-label>
              <input id="dialog-new-participant-future-custodian" matInput type="text" readonly [value]="fund.future_custodian?.document | emptyField | document | emptyField"/>
              <mat-hint *ngIf="fund.future_custodian?.id">
                {{ fund.future_custodian.name }}
                <br />
                <div class="participant-date" *ngIf="!!fund.future_custodian.date">
                  {{ fund.future_custodian.date | date: "shortDate" }}
                </div>
              </mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div class="future-participant" *ngIf="fund.future_manager; else emptyDiv">
          <div class="container-fields">
            <mat-form-field class="mb-15" class="mat-form-field-readonly">
              <mat-label>Gestor</mat-label>
              <input id="dialog-new-participant-future-manager" matInput type="text" readonly [value]="fund.future_manager?.document | emptyField | document | emptyField"/>
              <mat-hint *ngIf="fund.future_manager?.id">
                {{ fund.future_manager.name }}
                <br />
                <div class="participant-date" *ngIf="!!fund.future_manager.date">
                  {{ fund.future_manager.date | date: "shortDate" }}
                </div>
              </mat-hint>
            </mat-form-field>
          </div>
        </div>

        <!-- Future Participants - first line on grid - END -->

        <!-- Active Participants - second line on grid - BEGIN -->

        <div class="container-fields">
          <mat-form-field class="mb-15" class="mat-form-field-readonly">
            <mat-label>Administrador</mat-label>
            <input id="dialog-new-participant-active-administrator" matInput type="text" readonly [value]="fund.administrator?.document | emptyField | document | emptyField"/>
            <mat-hint *ngIf="fund.administrator?.id">
              {{ fund.administrator.name }}
              <br />
              <div class="participant-date" *ngIf="!!fund.administrator.date">
                {{ fund.administrator.date | date: "shortDate" }}
                <span *ngIf="fund.administrator.date_final">
                  - {{ fund.administrator.date_final | date: "shortDate" }}
                </span>
              </div>
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="container-fields">
          <mat-form-field class="mb-15" class="mat-form-field-readonly">
            <mat-label>Controlador do Ativo</mat-label>
            <input id="dialog-new-participant-active-asset-controller" matInput type="text" readonly [value]="fund.asset_controller?.document | emptyField | document | emptyField"/>
            <mat-hint *ngIf="fund.asset_controller?.id">
              {{ fund.asset_controller.name }}
              <br />
              <div class="participant-date" *ngIf="!!fund.asset_controller.date">
                {{ fund.asset_controller.date | date: "shortDate" }}
                <span *ngIf="fund.asset_controller.date_final">
                  - {{ fund.asset_controller.date_final | date: "shortDate" }}
                </span>
              </div>
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="container-fields">
          <mat-form-field class="mb-15" class="mat-form-field-readonly">
            <mat-label>Controlador do Passivo</mat-label>
            <input id="dialog-new-participant-active-liability-controller" matInput type="text" readonly [value]="fund.liability_controller?.document | emptyField | document | emptyField"/>
            <mat-hint *ngIf="fund.liability_controller?.id">
              {{ fund.liability_controller.name }}
              <br />
              <div class="participant-date" *ngIf="!!fund.liability_controller.date">
                {{ fund.liability_controller.date | date: "shortDate" }}
                <span *ngIf="fund.liability_controller.date_final">
                  - {{ fund.liability_controller.date_final | date: "shortDate" }}
                </span>
              </div>
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="container-fields">
          <mat-form-field class="mb-15" class="mat-form-field-readonly">
            <mat-label>Custodiante</mat-label>
            <input id="dialog-new-participant-active-custodian" matInput type="text" readonly [value]="fund.custodian?.document | emptyField | document | emptyField"/>
            <mat-hint *ngIf="fund.custodian?.id">
              {{ fund.custodian.name }}
              <br />
              <div class="participant-date" *ngIf="!!fund.custodian.date">
                {{ fund.custodian.date | date: "shortDate" }}
                <span *ngIf="fund.custodian.date_final">
                  - {{ fund.custodian.date_final | date: "shortDate" }}
                </span>
              </div>
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="container-fields">
          <mat-form-field class="mb-15" class="mat-form-field-readonly">
            <mat-label>Gestor</mat-label>
            <input id="dialog-new-participant-active-manager" matInput type="text" readonly [value]="fund.manager?.document | emptyField | document | emptyField"/>
            <mat-hint *ngIf="fund.manager?.id">
              {{ fund.manager.name }}
              <br />
              <div class="participant-date" *ngIf="!!fund.manager.date">
                {{ fund.manager.date | date: "shortDate" }}
                <span *ngIf="fund.manager.date_final">
                  - {{ fund.manager.date_final | date: "shortDate" }}
                </span>
              </div>
            </mat-hint>
          </mat-form-field>
        </div>
        <!-- Active Participants - second line on grid - END -->
      </div>
      <div
        class="btn-actions"
        fxLayout="row"
        fxLayoutGap="8px"
        fxLayoutAlign="end center"
      >
        <button
          id="cancel-button"
          mat-flat-button
          class="default"
          type="button"
          (click)="cancel(i)"
        >
          Adiar
        </button>
        <button
          id="reject-button"
          mat-flat-button
          color="accent"
          type="button"
          (click)="reject(i)"
        >
          Rejeitar
        </button>
        <button
          id="confirm-button"
          mat-flat-button
          color="accent"
          type="button"
          (click)="confirm(i)"
        >
          Confirmar
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #emptyDiv>
  <div class="empty-content">&nbsp;</div>
</ng-template>
