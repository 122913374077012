import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[galHourMinute]'
})
export class HourMinuteDirective implements OnInit, OnDestroy, OnChanges {
  unMask = /\D+/g;
  private subscriber: Subscription | undefined;

  constructor(private elementRef: ElementRef, private model: NgControl) {}

  ngOnInit(): void {
    this.subscriber = this.model?.control?.valueChanges.subscribe(() => {
      this.apply();
    });
    this.apply();
  }

  apply(): void {
    let newValue: string = this.elementRef.nativeElement.value.replace(new RegExp(this.unMask), '');

    newValue = newValue.replace(/(\d{2})(\d)/, '$1:$2');

    this.model?.control?.setValue(newValue, {
      emitEvent: false,
      emitModelToViewChange: false,
      emitViewToModelChange: false
    });

    this.elementRef.nativeElement.value = newValue;
  }

  ngOnDestroy() {
    this.subscriber?.unsubscribe();
  }
  ngOnChanges(): void {
    this.apply();
  }
}
