import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { CommonModule, DatePipe } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { PageTitleComponent, StepperTitleComponent } from '@gal/shared/components';
import {
  AlphanumericOnlyDirective,
  DatepickerMaxRangeDirective,
  DocumentMaskDirective,
  DragDropFileDirective,
  HourMinuteDirective,
  IntegerOnlyDirective,
  PhoneMaskDirective
} from '@gal/shared/directives';
import { DocumentPipe, EmptyFieldPipe, PhonePipe, UtcDatePipe } from '@gal/shared/pipes';
import { ChipComponent } from './components/chip/chip.component';
import { DialogConfirmationComponent } from './components/dialog-confirmation/dialog-confirmation.component';
// eslint-disable-next-line max-len
import { DialogWithConfirmationJustificationComponent } from './components/dialog-with-confirmation-justification/dialog-with-confirmation-justification.component';
import { StepperHeaderComponent } from './components/stepper-header/stepper-header.component';
import { DatepickerMaskDirective } from './directives/datepicker-mask.directive';
import { MaterialModule } from './material.module';
import { BigDecimalPipe } from './pipes/big-decimal.pipe';
import { SharedInjectorInstance } from './shared-injector-instance';

@NgModule({
  declarations: [
    PageTitleComponent,
    StepperTitleComponent,
    DocumentPipe,
    DocumentMaskDirective,
    PhoneMaskDirective,
    IntegerOnlyDirective,
    DialogConfirmationComponent,
    DialogWithConfirmationJustificationComponent,
    StepperHeaderComponent,
    EmptyFieldPipe,
    HourMinuteDirective,
    DragDropFileDirective,
    DatepickerMaskDirective,
    DatepickerMaxRangeDirective,
    ChipComponent,
    BigDecimalPipe,
    UtcDatePipe,
    AlphanumericOnlyDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    PageTitleComponent,
    StepperTitleComponent,
    StepperHeaderComponent,
    ChipComponent,
    DocumentPipe,
    EmptyFieldPipe,
    DocumentMaskDirective,
    PhoneMaskDirective,
    IntegerOnlyDirective,
    HourMinuteDirective,
    DatepickerMaskDirective,
    DatepickerMaxRangeDirective,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    DragDropFileDirective,
    BigDecimalPipe,
    UtcDatePipe,
    AlphanumericOnlyDirective
  ],
  entryComponents: [PageTitleComponent, StepperTitleComponent, DialogConfirmationComponent, StepperHeaderComponent, ChipComponent],
  providers: [
    DocumentPipe,
    EmptyFieldPipe,
    PhonePipe,
    DatePipe,
    UtcDatePipe,
    NgxMatMomentModule,
    { provide: MAT_DATE_LOCALE, useValue: 'pt' },
    {
      provide: NGX_MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'YYYY-MM-DD HH:mm'
        },
        display: {
          dateInput: 'DD/MM/YYYY HH:mm',
          monthYearLabel: 'MM yyyy',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY'
        }
      }
    },

    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['l', 'LL']
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY'
        }
      }
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' }
    }
  ]
})
export class SharedModule {
  constructor(private injector: Injector) {
    SharedInjectorInstance.setInjector(this.injector);
  }
}
