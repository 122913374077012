import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';

export const DateNotWeekend =
  () =>
    (control: AbstractControl): ValidationErrors | null => {
      const dateToCheck = moment(control.value);
      if (!dateToCheck || !control.value) {
        return null;
      }

      if (dateToCheck.day() === 0 || dateToCheck.day() === 6) {
        return { dateWeekend: true };
      } else {
        return null;
      }
    };
