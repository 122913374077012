<div
  class="loading-div mat-elevation-z8"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="8px"
  (click)="closeOvelayRef()"
>
  <ng-container>
    <mat-icon color="primary" class="success"> check_circle_outline </mat-icon>
    <span> Download concluído. </span>
  </ng-container>
</div>
