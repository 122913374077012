import { FlowTypeEnum } from '@gal/core/models/flow-type.enum';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanDeactivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AuthService } from '@gal/core/services/auth.service';
import { Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { RouteGuardData } from '../models/route-guard-data.model';
import { RouteFlowTypeRoles } from './../models/route-flow-type-roles.model';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {
  userAuthObservable?: Observable<any>;

  constructor(private auth: AuthService, private router: Router, private msalGuard: MsalGuard) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const guardData = route.routeConfig?.data ?? undefined;
    return this.validateRoute(guardData).pipe(
      tap(canAccess => {
        if (!canAccess) {
          this.router.navigate([this.auth.initialUrl]);
        }
      })
    );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const guardData = route?.data ?? undefined;
    return this.validateRoute(guardData);
  }

  validateRoute(guardData?: RouteGuardData): Observable<boolean> {
    return this.validateUserExists().pipe(
      map(
        validate =>
          !!validate &&
          (this.validateUserIsGalgo(guardData?.guard?.canGalgoAccess) ||
            this.validateUserIsMaster(guardData?.guard?.canMasterAccess) ||
            this.validateUserFlowTypeRoles(guardData?.guard?.canRolesAccess) ||
            this.validateFlow(guardData?.guard?.canFlowAccess) ||
            !!guardData?.guard?.canAnyAccess)
      )
    );
  }

  validateUserIsGalgo(onlyGalgo?: boolean): boolean {
    return !!onlyGalgo ? !!this.auth.userInfo?.galgo : false;
  }

  validateUserIsMaster(onlyMaster?: boolean): boolean {
    const isMaster = this.auth.getSelectedParticipant()?.is_master;
    return !!onlyMaster ? !!isMaster : false;
  }

  validateUserFlowTypeRoles(onlyFlowTypeRoles?: RouteFlowTypeRoles[]): boolean {
    const rolesList = this.auth.getSelectedParticipant()?.user_roles;
    const flowTypeSelected = this.auth.getSelectedFlowType();
    const flowTypeRoles = onlyFlowTypeRoles?.find(item => item.flowType === flowTypeSelected.id);

    let canAccess = false;

    if (!!flowTypeRoles && !!rolesList && Array.isArray(rolesList)) {
      rolesList.forEach(r => {
        if (flowTypeRoles.roles.includes(r)) {
          canAccess = true;
        }
      });
    }

    return !!flowTypeRoles ? !!canAccess : false;
  }

  validateFlow(flows?: Array<string>): boolean {
    if (!flows) {
      return false;
    }

    const flowTypeSelected = this.auth.getSelectedFlowType();
    return !!flows ? !!flows?.find(flow => flow === flowTypeSelected.id) : false;
  }

  validateUserExists(): Observable<boolean> {
    return of(true).pipe(
      mergeMap(() => {
        if (!!this.auth.userInfo) {
          return of(true);
        } else {
          if (!this.userAuthObservable) {
            this.userAuthObservable = this.auth.getUserData();
          }
          return this.userAuthObservable.pipe(map(() => !!this.auth.userInfo));
        }
      })
    );
  }
}
