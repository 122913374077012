import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from '@environment';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, timeout } from 'rxjs/operators';
import { IdleService } from './idle.service';
import { LoadingService } from './loading.service';
import { SnackbarService } from './snackbar.service';

export interface ApiOptions {
  showLoading?: boolean;
  defaultErrorHandling?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  logoutUser = new BroadcastChannel('logoutUser');

  private apiUrl = Environment.apiUrl;

  constructor(
    private http: HttpClient,
    private loading: LoadingService,
    private snackBar: SnackbarService,
    private idleService: IdleService
  ) {}

  get<T = any>(path: string, params: any = {}, options: ApiOptions = {}): Observable<T> {
    return this.http
      .get<T>(`${this.apiUrl}${path}`, {
        params: new HttpParams({ fromObject: params })
      })
      .pipe(this.handleDefaultOptions(options));
  }

  getWithoutAuthentication<T = any>(path: string, options: ApiOptions = {}): Observable<T> {
    const headers = new HttpHeaders().set('NoAuthentication', 'true');
    return this.http.get<T>(`${this.apiUrl}${path}`, { headers }).pipe(this.handleDefaultOptions(options));
  }

  post<T = any>(path: string, body: any, options: ApiOptions = {}): Observable<T> {
    return this.http.post<T>(`${this.apiUrl}${path}`, body).pipe(this.handleDefaultOptions(options));
  }

  postFormData<T = any>(path: string, body: any, options: ApiOptions = {}): Observable<T> {
    let headers = new HttpHeaders();
    headers = headers.append('ContentTypeAuto', 'true');

    return this.http.post<T>(`${this.apiUrl}${path}`, body, { headers }).pipe(this.handleDefaultOptions(options));
  }

  put<T = any>(path: string, body: any, options: ApiOptions = {}): Observable<T> {
    return this.http.put<T>(`${this.apiUrl}${path}`, body).pipe(this.handleDefaultOptions(options));
  }

  delete<T = any>(path: string, options: ApiOptions = {}): Observable<T> {
    return this.http.delete<T>(`${this.apiUrl}${path}`).pipe(this.handleDefaultOptions(options));
  }

  patch<T = any>(path: string, body: any, options: ApiOptions = {}): Observable<T> {
    return this.http.patch<T>(`${this.apiUrl}${path}`, body).pipe(this.handleDefaultOptions(options));
  }

  handleDefaultOptions(options: ApiOptions): <T>(source: Observable<T>) => Observable<T> {
    const timeoutTime = 180000;

    if (options.showLoading) {
      this.loading.show();
    }

    return <T>(source: Observable<T>) =>
      source.pipe(
        filter(() => !this.idleService.sessionExpired),
        timeout(timeoutTime),
        catchError(error => {
          // Status 401 is used when the logged in user has the profile modified
          if (error?.status === 401) {
            this.snackBar.showError('Suas permissões foram alteradas, será necessário realizar um novo login.');
            setTimeout(() => this.logoutUser.postMessage('Logout User'), 2500);
          } else if (options.defaultErrorHandling) {
            if (error?.error?.errors?.length > 0) {
              this.snackBar.showError(error.error.errors[0].message);
            } else if (error?.status === 404) {
              this.snackBar.showError('Registro não encontrado');
            } else if (!!error?.error?.message && error.status === 422) {
              this.snackBar.showError(error.error.message);
            } else {
              this.snackBar.showError();
            }
          }
          return throwError(error);
        }),
        finalize(() => {
          if (options.showLoading) {
            this.loading.hide();
          }
        })
      );
  }

  downloadFile<T = any>(fileUrl: any, fileName: string): Observable<T> {
    return new Observable(obs => {
      this.http.get(fileUrl, { responseType: 'blob' }).subscribe(object => {
        const url = URL.createObjectURL(object);
        const link = document.createElement('a');
        if (typeof link.download === 'string') {
          document.body.appendChild(link);
          link.download = fileName;
          link.href = url;
          link.target = '_blank';
          link.click();
          setTimeout(() => {
            URL.revokeObjectURL(url);
            document.body.removeChild(link);
            obs.next();
            obs.complete();
          });
        } else {
          obs.error('Erro ao realizar download do arquivo');
        }
      });
    });
  }
}
