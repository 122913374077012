<div
  class="loading-div mat-elevation-z8"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="8px"
>
  <ng-container>
    <mat-spinner
      class="loading"
      color="primary"
      [strokeWidth]="5"
      [diameter]="30"
    >
    </mat-spinner>
    <span> O download iniciará logo. </span>
  </ng-container>
</div>
