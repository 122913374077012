const regexAlphanumericWithAccentsAndTheAtSign = /^[0-9a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇ_\-:,./\s@]*/;
const regexAlphanumericWithTheAtSign = /^[0-9a-zA-Z@]*/;

export const AlphanumericFormat = (text: string, includeAccents: boolean = false): string => {
  const regex = new RegExp(includeAccents ? regexAlphanumericWithAccentsAndTheAtSign : regexAlphanumericWithTheAtSign);
  let textRegex = text.match(regex)?.toString() || '';
  textRegex = textRegex.replace(/[--]+/g, '-');
  textRegex = textRegex.replace(/[..]+/g, '.');
  textRegex = textRegex.replace(/[,,]+/g, ',');
  textRegex = textRegex.replace(/[::]+/g, ':');
  textRegex = textRegex.replace(/[//]+/g, '/');
  return textRegex;
};