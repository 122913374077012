import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PreviousUrlService } from '@gal/core/services/previous-url.service';
import { filter } from 'rxjs/operators';

interface IBreadCrumb {
  label: string;
  route: string;
  root?: boolean;
}
interface IRouteData {
  title: string;
  extraBreadcrumb: IBreadCrumb[];
}

@Component({
  selector: 'gal-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs: IBreadCrumb[];

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private previousUrl: PreviousUrlService) {
    this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => (this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root)));
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadCrumb[] = []): IBreadCrumb[] {
    const routeData: IRouteData | null = (route?.routeConfig?.data as IRouteData) ?? null;
    const label = routeData?.title ?? '';
    let path = route?.routeConfig?.path ?? '';

    if (!!route.snapshot) {
      Object.keys(route.snapshot.params).forEach(key => {
        if (path.indexOf(`/:${key}`)) {
          path = path.replace(`/:${key}`, `/${route.snapshot.params[key]}`);
        }
      });
    }

    const nextUrl = path ? `${url}/${path}` : url;

    const breadcrumb: IBreadCrumb = {
      label,
      route: nextUrl
    };
    const extraBreadcrumbs: IBreadCrumb[] = JSON.parse(JSON.stringify(routeData?.extraBreadcrumb ?? []));
    extraBreadcrumbs?.map(e => (!!e.root ? e.route : (e.route = url + e.route)));
    const newBreadcrumbs = breadcrumb.label ? [...breadcrumbs, ...(extraBreadcrumbs ?? []), breadcrumb] : [...breadcrumbs];

    if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }
}
