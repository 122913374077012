import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '@gal/core';
import { FundService } from '@gal/fund/services';
// eslint-disable-next-line max-len
import { DialogWithConfirmationJustificationComponent } from '@gal/shared/components/dialog-with-confirmation-justification/dialog-with-confirmation-justification.component';
import { RolesEnum } from '@gal/shared/models';
import { SharedInjectorInstance } from '@gal/shared/shared-injector-instance';
import { FundParticipantNotifications } from './../models/fund-participant-notifications.model';

@Component({
  selector: 'gal-dialog-new-participant-notification',
  templateUrl: './dialog-new-participant-notification.component.html',
  styleUrls: ['./dialog-new-participant-notification.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogNewParticipantNotificationComponent {
  constructor(
    private dialogRef: MatDialogRef<DialogNewParticipantNotificationComponent | boolean>,
    @Inject(MAT_DIALOG_DATA) public data: FundParticipantNotifications,
    private fundService: FundService,
    private snackBar: SnackbarService
  ) {}

  static open(data?: FundParticipantNotifications): MatDialogRef<DialogNewParticipantNotificationComponent | boolean> {
    const dialog: MatDialog = SharedInjectorInstance.getInjector().get<MatDialog>(MatDialog);
    return dialog.open(DialogNewParticipantNotificationComponent, { data });
  }

  confirm(index: number) {
    const fund = this.data.administrator[index];

    this.fundService.confirmParticipantNotification(fund.id, RolesEnum.ADMINISTRADOR).subscribe(() => {
      this.data.administrator.splice(index, 1);
      this.snackBar.showSuccess('Confirmação realizada com sucesso.');
      this.canCloseAndClose();
    });
  }

  reject(index: number) {
    const fund = this.data.administrator[index];

    DialogWithConfirmationJustificationComponent.open({
      title: 'Justificativa',
      message: 'Informe a justificativa para rejeitar seu novo papel no fundo:'
    })
      .afterClosed()
      .subscribe(data => {
        if (!!data?.confirmation && !!data?.justification) {
          this.fundService.rejectParticipantNotification(fund.id, RolesEnum.ADMINISTRADOR, data.justification).subscribe(() => {
            this.data.administrator.splice(index, 1);
            this.snackBar.showSuccess('Confirmação realizada com sucesso.');
            this.canCloseAndClose();
          });
        }
      });
  }

  cancel(index: number): void {
    this.data.administrator.splice(index, 1);
    this.snackBar.showInfo('Notificação adiada com sucesso.');
    this.canCloseAndClose();
  }

  private canCloseAndClose(): void {
    if (!this.data.administrator.length) {
      this.dialogRef.close(true);
    }
  }
}
