import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gal-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent {
  @Input() color = 'accent';
  @Input() showChip = true;
}
