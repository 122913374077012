<mat-toolbar color="accent" fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayoutAlign="start center" fxLayoutGap="20px">
    <img height="45" src="/assets/img/border_logo.svg" />
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
    <div fxLayout="row" fxLayoutGap="5px">
      <div fxLayout="column" fxLayoutAlign="center end">
        <span class="user-name ellipsis" matTooltip="{{ userInfo?.name }}">{{ userInfo?.name }}</span>
      </div>
      <mat-icon class="user-avatar">account_circle</mat-icon>
    </div>
    <div class="vertical-separator"></div>
    <div>
      <mat-form-field
        class="select flow-type mat-form-field-no-padding mat-focused"
        id="header-select-flow-type-form"
      >
        <mat-label>Fluxo</mat-label>
        <mat-select
          color="primary"
          [formControl]="selectedFlowTypeControl"
          id="header-select-flow-type-input"
        >
          <mat-option
            class="option"
            id="header-select-flow-type-option-{{ i }}"
            *ngFor="let flow of flowTypes; let i = index"
            [value]="flow"
          >
            {{ flow.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field
        class="select institution mat-form-field-no-padding mat-focused"
        id="header-select-participant-form"
      >
        <mat-label>Participante</mat-label>
        <input
          type="text"
          #inputParticipant
          (blur)="checkIsSelectedParticipant()"
          matInput
          id="header-select-participant-input"
          [formControl]="selectedParticipantControl"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete
          (optionSelected)="inputParticipant.blur()"
          [displayWith]="getParticipantName"
          #auto="matAutocomplete"
          id="header-select-participant-autocomplete"
        >
          <mat-option
            id="header-select-participant-select-option-{{ i }}"
            class="option"
            *ngFor="let c of filteredParticipant | async; let i = index"
            [value]="c"
          >
            {{ c.nickname }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="vertical-separator"></div>
    <div fxLayout="column" fxLayoutAlign="center center">
      <mat-icon class="exit-icon" id="logout-icon" aria-hidden="false" aria-label="Sair" (click)="logout()">
        exit_to_app
      </mat-icon>
      <button class="config-btn" id="logout-button" (click)="logout()" mat-button>Sair</button>
    </div>
  </div>
</mat-toolbar>
