import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[galDatepickerMask]'
})
export class DatepickerMaskDirective {
  @Input('galDatepickerMask') type: 'date' | 'datetime' = 'date';

  constructor(private e: ElementRef<HTMLInputElement>, private control: NgControl) {}

  @HostListener('input', ['$event']) onInputChange(event: InputEvent) {
    const initalValue = this.e.nativeElement.value;
    const digits = initalValue.replace(/\D+/g, '');

    const transformedValue = this.type === 'date' ? this.formatToDate(digits) : this.formatToDateTime(digits);
    this.e.nativeElement.value = transformedValue;

    if (initalValue !== transformedValue) {
      event.stopPropagation();
    }
  }

  formatToDate(value: string): string {
    value = value.substr(0, 8);
    let transformedValue = value;
    if (value.length > 2) {
      transformedValue = value.replace(/^(\d{2,2})(\d)/, '$1/$2');
    }
    if (value.length > 4) {
      transformedValue = value.replace(/^(\d{2,2})(\d{2,2})(\d)/, '$1/$2/$3');
    }
    return transformedValue;
  }

  formatToDateTime(value: string): string {
    value = value.substr(0, 14);
    let transformedValue = value;
    if (value.length > 2) {
      transformedValue = value.replace(/^(\d{2,2})(\d)/, '$1/$2');
    }
    if (value.length > 4) {
      transformedValue = value.replace(/^(\d{2,2})(\d{2,2})(\d)/, '$1/$2/$3');
    }
    if (value.length > 8) {
      transformedValue = value.replace(/^(\d{2,2})(\d{2,2})(\d{4,4})(\d)/, '$1/$2/$3, $4');
    }
    if (value.length > 10) {
      transformedValue = value.replace(/^(\d{2,2})(\d{2,2})(\d{4,4})(\d{2,2})(\d)/, '$1/$2/$3, $4:$5');
    }
    if (value.length > 12) {
      transformedValue = value.replace(/^(\d{2,2})(\d{2,2})(\d{4,4})(\d{2,2})(\d{2,2})(\d)/, '$1/$2/$3, $4:$5:$6');
    }
    return transformedValue;
  }
}
