import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { B2CPolicies, Common } from '@environment';
import { ActivityCheckService } from '@gal/activity-check/services/activity-check.service';
import { PathDatetimeNow } from '@gal/app.module';
import { Auth } from '@gal/core/models/auth.model';
import { FlowType } from '@gal/core/models/flow-type.model';
import { ActionNotificationService } from '@gal/core/services/action-notification.service';
import { SnackbarService } from '@gal/core/services/snackbar.service';
// eslint-disable-next-line
import { DialogNewParticipantNotificationComponent } from '@gal/fund/dialog-new-participant-notification/dialog-new-participant-notification.component';
import { FundParticipantNotifications } from '@gal/fund/models/fund-participant-notifications.model';
import { Participant } from '@gal/participant/models';
import { DialogConfirmationComponent } from '@gal/shared/components';
import { Role, RolesEnum } from '@gal/shared/models';
import { retryBackoff } from 'backoff-rxjs';
import { interval, Observable, of, Subject, zip } from 'rxjs';
import { catchError, delay, filter, first, map, mergeMap, repeatWhen, startWith, takeWhile, tap } from 'rxjs/operators';
import { AuthParticipant } from '../models/auth-participant.model';
import { TokenUser } from '../models/token-user.model';
import { ApiService } from './api.service';
import { IdleService } from './idle.service';

interface Payload extends AuthenticationResult {
  idTokenClaims: {
    tfp?: string;
  };
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  static COUNTRY_BR_CODE = 105;

  userInfo?: Auth;
  flowTypes: FlowType[] = [
    { id: '1', description: 'FoF' },
    { id: '2', description: 'PCO' },
    { id: '3', description: 'Come-Cotas' }
  ];
  updateHeaderSubject = new Subject();
  updateFlowTypeSubject = new Subject();
  logoutEvent = new BroadcastChannel('logout');
  logoutUser = new BroadcastChannel('logoutUser');

  keyForcedLogout = '_forcedLogout';
  initialUrl = '/home';

  private KEY_STORAGE_FLOWTYPE = 'FlowType';
  private KEY_STORAGE_PARTICIPANT = 'Participant';

  private userID?: string;
  private participantSelectedID?: string;
  private participantUrl = Common.participantApiUrl;
  private endSession = false;
  private flowTypeSelectedID?: string;

  private loggedIntervalStarted = false;
  private loggedUser = false;

  constructor(
    private api: ApiService,
    private router: Router,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private idleService: IdleService,
    private snackBar: SnackbarService,
    private actionNotificationService: ActionNotificationService,
    private activityCheckService: ActivityCheckService
  ) {
    this.initAutoLogout();
    this.initMsalEvents();
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest, prompt: 'login' } as RedirectRequest);
    } else {
      if (userFlowRequest) {
        userFlowRequest.prompt = 'login';
      }
      this.msalService.loginRedirect(userFlowRequest);
    }
  }

  logout(wasForcedLogout: boolean = false) {
    this.clearSession();
    this.logoutEvent.postMessage('Logout clear');
    if (!!wasForcedLogout) {
      localStorage.setItem(this.keyForcedLogout, 'true');
    }
    this.msalService.logoutRedirect();
  }

  changePassword(redirectToHome?: boolean) {
    const changePasswordFlowRequest: RedirectRequest = {
      scopes: ['openid'],
      authority: B2CPolicies.authorities.passwordReset.authority
    };

    if (!!redirectToHome) {
      changePasswordFlowRequest.redirectStartPage = this.initialUrl;
    }
    this.login(changePasswordFlowRequest);
  }

  getUserData(): Observable<any> {
    return of(true).pipe(
      mergeMap(() =>
        this.msalBroadcastService.inProgress$.pipe(
          repeatWhen(obs => obs.pipe(delay(100))),
          filter((status: InteractionStatus) => status === InteractionStatus.None),
          first()
        )
      ),
      tap(() => {
        const claims: any = this.msalService.instance.getActiveAccount()?.idTokenClaims;
        this.userID = claims?.border_id;
      }),
      mergeMap(() => {
        if (!!this.userID) {
          return of(this.userID).pipe(
            map(() => this.getUserInfo()),
            map(() => this.getCachedParticipant()),
            mergeMap(() => this.getAllParticipantsInfo()),
            map(() => this.getCachedFlowType()),
            map(() => this.getNotifications()),
            map(() => this.startKeepAliveInterval()),
            tap(() => this.updateHeaderSubject.next())
          );
        } else {
          return of(false);
        }
      })
    );
  }

  getSelectedParticipant(): AuthParticipant | undefined {
    let participantSelected = this.userInfo?.participants?.find(part => part.id === this.participantSelectedID);

    if (!participantSelected && !!this.userInfo?.participants && !!this.userInfo?.galgo) {
      participantSelected = this.userInfo?.participants[0];
    }

    return participantSelected;
  }

  selectParticipant(participantId: string): void {
    this.participantSelectedID = participantId;
    localStorage.setItem(`${this.KEY_STORAGE_PARTICIPANT}${this.userID}`, participantId);
    const currentRoute = this.router.url;
    this.router.navigateByUrl('/reload', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentRoute]).then(() => {
        this.getNotifications();
      });
    });
  }

  getSelectedFlowType(): FlowType {
    return this.flowTypes.find(flow => flow.id === this.flowTypeSelectedID) || this.flowTypes[0];
  }

  selectFlowType(flowTypeId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.flowTypeSelectedID = flowTypeId;
      localStorage.setItem(`FlowType${this.userID}`, flowTypeId);
      const currentRoute = this.router.url;
      this.router.navigateByUrl('/reload', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentRoute]).then(() => {
          this.getNotifications();
          resolve('');
        });
      });
      this.updateFlowTypeSubject.next();
    });
  }

  userHasRole(role: RolesEnum): boolean {
    return !!this.getSelectedParticipant()?.user_roles?.includes(role);
  }

  getDatetimeServer(): Observable<any> {
    return this.api.getWithoutAuthentication(PathDatetimeNow, {
      defaultErrorHandling: false,
      showLoading: false
    });
  }

  private initAutoLogout() {
    this.logoutEvent.onmessage = () => this.clearSession();
    this.logoutUser.onmessage = () => this.logout();

    this.idleService.startWatching(900, 300).subscribe(() => {
      if (!this.router.url.includes(this.initialUrl)) {
        this.logout(true);
      }
    });
  }

  private initMsalEvents() {
    this.msalService.handleRedirectObservable().subscribe(
      data => {},
      err => {
        if (err.message.includes('AADB2C90118')) {
          this.changePassword();
        } else {
          this.login();
        }
      }
    );

    this.msalBroadcastService.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
      .subscribe(() => this.checkAndSetActiveAccount());

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS))
      .subscribe((result: EventMessage) => {
        const payload: Payload = result.payload as AuthenticationResult;
        if (this.router.url === '/login-failed') {
          this.router.navigate([this.initialUrl]);
        }
        if (result.eventType === EventType.LOGIN_SUCCESS) {
          this.idleService.clearInteractions();
        }

        if (payload.idTokenClaims['tfp'] === B2CPolicies.names.passwordReset) {
          window.alert('Profile has been updated successfully. \nPlease sign-in again.');
          return this.logout();
        }

        if (!!this.endSession) {
          this.logout();
        }
        return result;
      });

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE))
      .subscribe((result: EventMessage) => {
        this.clearSession();
        if (result.error?.message.includes('AADB2C90118')) {
          this.changePassword();
        } else if (result.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
          this.endSession = true;
          this.logout();
        }
      });
  }

  private clearSession() {
    window.sessionStorage.clear();
    this.idleService.clearInteractions();
    this.loggedUser = false;
    const cookies = document.cookie.split(';');
    for (const c of cookies) {
      const eqPos = c.indexOf('=');
      const name = eqPos > -1 ? c.substr(0, eqPos) : c;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
  }

  private getAllParticipants(): Observable<any> {
    return this.api.get(`${this.participantUrl}/?size=10000`, null, { showLoading: true }).pipe(map(r => r.participants));
  }

  private checkAndSetActiveAccount() {
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
      this.loggedUser = true;
    } else {
      const hasForcedLogout = localStorage.getItem(this.keyForcedLogout);

      if (!!hasForcedLogout && !!this.router.url.includes(this.initialUrl)) {
        localStorage.removeItem(this.keyForcedLogout);
        setTimeout(() => {
          DialogConfirmationComponent.open({
            title: 'Sessão expirou',
            message: 'Sua sessão expirou, faça o login novamente.',
            confirmBtn: 'OK',
            isOkBtn: true
          });
        }, 1000);
      }
    }
  }

  private getUserInfo(): Auth {
    const claims: any = this.msalService.instance.getActiveAccount()?.idTokenClaims;
    const authToken: TokenUser = JSON.parse(claims?.user);
    const auth: Auth = {
      id: claims?.border_id,
      galgo: authToken.is_galgo,
      name: claims?.name,
      participants: authToken.participants.map(p => ({
        id: p.id,
        user_roles: p.roles,
        is_master: p.is_master,
        code_country: 105,
        participant_roles: []
      }))
    };
    this.userInfo = auth;
    this.loggedUser = !!this.userInfo?.id;
    return auth;
  }

  private getCachedParticipant() {
    let participantID = localStorage.getItem(`${this.KEY_STORAGE_PARTICIPANT}${this.userID}`);
    if (
      this.userInfo?.participants &&
      (!participantID || (!this.userInfo?.participants?.find(part => part.id === participantID) && !this.userInfo?.galgo))
    ) {
      participantID = this.userInfo.participants[0].id;
      localStorage.setItem(`${this.KEY_STORAGE_PARTICIPANT}${this.userID}`, participantID ?? '');
    }
    this.participantSelectedID = participantID ?? '';
  }

  private getCachedFlowType() {
    let flowTypeID = localStorage.getItem(`${this.KEY_STORAGE_FLOWTYPE}${this.userID}`);
    if (!flowTypeID || !this.flowTypes?.find(flow => flow.id === flowTypeID)) {
      flowTypeID = this.flowTypes[0].id;
      localStorage.setItem(`${this.KEY_STORAGE_FLOWTYPE}${this.userID}`, flowTypeID ?? '');
    }
    this.flowTypeSelectedID = flowTypeID ?? '';
  }

  private getAllParticipantsInfo(): Observable<AuthParticipant[]> {
    return of(true).pipe(
      mergeMap(() => {
        if (this.userInfo?.galgo) {
          return this.getAllParticipants();
        } else {
          const obs: Observable<any>[] = [];
          this.userInfo?.participants?.forEach(p => {
            obs.push(this.getParticipantInfo(p.id));
          });
          return zip(...obs);
        }
      }),
      map((participants: any[]) =>
        participants
          .map((p: Participant) => {
            const userParticipantLink = this.userInfo?.participants?.find(userp => userp.id === p.id);
            const authParticipant: AuthParticipant = {
              ...userParticipantLink,
              id: p.id,
              code_country: p.country.code,
              name: p.name,
              nickname: p.nickname,
              document: p.document,
              domains: p.domains.filter((d: any) => !!d.active).map((d: any) => d.name),
              participant_roles: p.roles.sort((a: Role, b: Role) => a.name.localeCompare(b.name))
            };
            return authParticipant;
          })
          .sort((a, b) => (a.nickname && b.nickname ? a.nickname.localeCompare(b.nickname) : 0))
      ),
      tap(participants => {
        if (this.userInfo) {
          this.userInfo.participants = participants;
        }
      })
    );
  }

  private getParticipantInfo(participantId: string): Observable<any> {
    return this.api.get(`${this.participantUrl}/${participantId}`);
  }

  private getNotifications(): void {
    this.actionNotificationService.getNotifications();
    setTimeout(() => {
      this.validateUserNotifications();
    }, 1000);
  }

  private validateUserNotifications(): void {
    if (this.userHasRole(RolesEnum.ADMINISTRADOR) && !!this.participantSelectedID) {
      const keyNotification = `Notification${this.userID}-${this.participantSelectedID}`;
      const notificationID = sessionStorage.getItem(keyNotification);

      if (!notificationID) {
        sessionStorage.setItem(keyNotification, 'true');
        this.api
          .get<FundParticipantNotifications>(`${Common.fundApiUrl}/future-funds`, null, {
            defaultErrorHandling: false,
            showLoading: false
          })
          .subscribe(
            notifications => {
              if (!!notifications.administrator?.length) {
                setTimeout(() => {
                  DialogNewParticipantNotificationComponent.open(notifications)
                    .afterClosed()
                    .subscribe(data => {
                      if (!data) {
                        this.snackBar.showInfo('Notificação adiada com sucesso.');
                      }
                    });
                }, 1000);
              }
            },
            err => {
              sessionStorage.removeItem(keyNotification);
            }
          );
      }
    }
  }

  private startKeepAliveInterval(): void {
    if (!this.loggedIntervalStarted) {
      interval(30000)
        .pipe(
          startWith(0),
          mergeMap(() => this.activityCheckService.putActivityCheck().pipe(retryBackoff({ initialInterval: 200, maxRetries: 5 }))),
          catchError(() => of(null)),
          takeWhile(() => !!this.loggedUser)
        )
        .subscribe(() => {});

      this.loggedIntervalStarted = true;
    }
  }
}
