<div id="homepage" *ngIf="!isLoading">
  <header>
    <div class="topbar">
      <span *ngIf="!!welcomeLine">{{ welcomeLine }}seja bem-vindo.</span>
      <span *ngIf="!welcomeLine">Seja bem-vindo.</span>
    </div>
    <div class="logo">
      <img height="45" src="/assets/img/border_logo_color.svg" />
      <button type="button" id="redirect-login-button" mat-flat-button color="accent" class="login-btn" (click)="redirectToLogin()">
        LOGIN
      </button>
    </div>
    <div class="shadow-one"></div>
    <div class="shadow-two"></div>
  </header>
  <section class="main">
    <div class="description">UMA NOVA PLATAFORMA DE ROTEAMENTO DE ORDENS</div>
  </section>
  <footer>
    <div class="column">
      <a id="redirect-about-link" href="https://www.galgosistemas.com.br/border-pro">
        <span>Conheça o Border PRO</span>
        <span class="icon-redirect">
          <mat-icon> navigate_next </mat-icon>
        </span>
      </a>
    </div>
    <div class="column">
      <a id="redirect-galgo-link" href="https://www.galgosistemas.com.br/">
        <span>Galgo S.A.</span>
        <span class="icon-redirect">
          <mat-icon> navigate_next </mat-icon>
        </span>
      </a>
    </div>
    <div class="column">
      <a id="redirect-privacy-link" href="https://www.galgosistemas.com.br/privacidade">
        <span>Privacidade</span>
        <span>Proteção de Dados</span>
        <span>Política de Cookies</span>
        <span class="icon-redirect">
          <mat-icon> navigate_next </mat-icon>
        </span>
      </a>
    </div>
  </footer>
</div>
