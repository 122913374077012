import { AbstractControl, ValidationErrors } from '@angular/forms';

const calcDigitosPosicoes = (digitos: string, posicoes = 10): any => {
  // Garante que o valor são uma string
  digitos = digitos.toString();
  let somaDigitos = 0;
  // Faz a soma dos dí­gitos com a posição
  // Ex. para 10 posições:
  //   0    2    5    4    6    2    8    8   4
  // x10   x9   x8   x7   x6   x5   x4   x3  x2
  //   0 + 18 + 40 + 28 + 36 + 10 + 32 + 24 + 8 = 196
  [...digitos].forEach((v, i) => {
    // Preenche a soma com o dígito vezes a posição
    somaDigitos += Number(digitos[i]) * posicoes;

    // Subtrai 1 da posição
    posicoes--;

    // Parte  para CNPJ
    // Ex.: 5-4-3-2-9-8-7-6-5-4-3-2
    if (posicoes < 2) {
      // Retorno a posiçao para 9
      posicoes = 9;
    }
  });

  // Captura o resto da divisão entre soma_digitos dividido por 11
  // Ex.: 196 % 11 = 9
  somaDigitos %= 11;

  // Verifica se soma_digitos são menor que 2
  if (somaDigitos < 2) {
    // soma_digitos agora sera zero
    somaDigitos = 0;
  } else {
    // Se for maior que 2, o resultado é 11 menos soma_digitos
    // Ex.: 11 - 9 = 2
    // Nosso digito procurado é 2
    somaDigitos = 11 - somaDigitos;
  }

  // Concatena mais um digito aos primeiro nove digitos
  // Ex.: 025462884 + 2 = 0254628842
  const cpf = digitos + somaDigitos;

  // Retorna
  return cpf;
};

const validateCpf = (valor: string): boolean => {
  // Garante que o valor é uma string
  valor = valor.toString();

  // Remove caracteres invalidos do valor
  valor = valor.replace(/[^0-9]/g, '');
  const invalidValues = [
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999'
  ];

  if (invalidValues.includes(valor)) {
    return false;
  }

  // Captura os 9 primeiros digitos do CPF
  // Ex.: 02546288423 = 025462884
  const digitos = valor.substr(0, 9);

  // Faz o calculo dos 9 primeiros digitos do CPF para obter o primeiro digito
  let novoCpf = calcDigitosPosicoes(digitos);

  // Faz o calculo dos 10 digitos do CPF para obter o ultimo digito
  novoCpf = calcDigitosPosicoes(novoCpf, 11);

  // Verifica se o novo CPF gerado é identico ao CPF enviado
  return novoCpf === valor;
};

const validateCnpj = (valor: string): boolean => {
  // Garante que o valor é uma string
  valor = valor.toString();

  // Remove caracteres invalidos do valor
  valor = valor.replace(/[^0-9]/g, '');

  const invalidValues = [
    '00000000000000',
    '11111111111111',
    '22222222222222',
    '33333333333333',
    '44444444444444',
    '55555555555555',
    '66666666666666',
    '77777777777777',
    '88888888888888',
    '99999999999999'
  ];

  if (invalidValues.includes(valor)) {
    return false;
  }

  // O valor original
  const cnpjOriginal = valor;

  // Captura os primeiros 12 numeros do CNPJ
  const primeirosNumerosCnpj = valor.substr(0, 12);

  // Faz o primeiro calculo
  const primeiroCalculo = calcDigitosPosicoes(primeirosNumerosCnpj, 5);

  // O segundo calculo é a mesma coisa do primeiro, porém, começa na posiçao 6
  const segundoCalculo = calcDigitosPosicoes(primeiroCalculo, 6);

  // Concatena o segundo digito ao CNPJ
  const cnpj = segundoCalculo;

  // Verifica se o CNPJ gerado é identico ao enviado
  return cnpj === cnpjOriginal;
};

export const DocumentValidator = (control: AbstractControl): ValidationErrors | null => {
  const value = control.value || '';
  const length = value.replace(/[^0-9]/g, '').length;
  return (length > 11 ? validateCnpj(value) : validateCpf(value)) ? null : { document: true };
};

export const DocumentCNPJValidator = (control: AbstractControl): ValidationErrors | null => {
  const value = control.value || '';
  return validateCnpj(value) ? null : { document: true };
};

export const DocumentValidatorConditional =
  (conditionControl: AbstractControl, conditionValue: any | any[], objectField?: string) =>
    (control: AbstractControl): ValidationErrors | null => {
      const value = control.value || '';
      const conditionControlValue = objectField && !!conditionControl.value ? conditionControl.value[objectField] : conditionControl.value;
      const isArray = conditionValue.constructor === Array;
      if (isArray ? conditionValue.includes(conditionControlValue) : conditionValue === conditionControlValue) {
        const length = value.replace(/[^0-9]/g, '').length;
        return (length > 11 ? validateCnpj(value) : validateCpf(value)) ? null : { document: true };
      } else {
        return null;
      }
    };
