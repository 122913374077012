import { Injectable } from "@angular/core";
import { ApiService } from "@gal/core/services/api.service";
import { Observable } from "rxjs";
import { LinkUser } from "../models/link-user.model";
import { LinkUserDetail } from "../models/link-user-detail.model";
import { NewExternalUser } from "../models/new-external-user.model";
import { Common } from "@environment";
import { EmailExist } from "../models/email-exist.model";

@Injectable({
  providedIn: 'root'
})
export class NewUserService {
  private apiUrl = Common.userApiUrl;

  constructor(private api: ApiService) {}

  getEmailExist(email: string, participant: string): Observable<EmailExist> {
    return this.api.get<EmailExist>(`${this.apiUrl}/user-creation-link/external/${email}/${participant}/exist`, {
      defaultErrorHandling: true,
      showLoading: true
    });
  }

  postLinkUser(participantId: string): Observable<LinkUser> {
    const participant = {
      participant_id: participantId
    }
    return this.api.post<LinkUser>(`${this.apiUrl}/user-creation-link`, participant, {
      defaultErrorHandling: false,
      showLoading: true
    });
  }

  getExternalLinkUser(base64LinkCode: string): Observable<LinkUserDetail> {
    return this.api.get<LinkUserDetail>(`${this.apiUrl}/user-creation-link/external/${base64LinkCode}`, {
      defaultErrorHandling: false,
      showLoading: false
    });
  }

  getLinkUserByParticipant(participantId: string): Observable<LinkUser> {
    return this.api.get<LinkUser>(`${this.apiUrl}/user-creation-link/participant/${participantId}`, {
      defaultErrorHandling: true,
      showLoading: true
    });
  }

  getLinkUser(base64LinkCode: string): Observable<LinkUserDetail> {
    return this.api.get<LinkUserDetail>(`${this.apiUrl}/user-creation-link/${base64LinkCode}`, {
      defaultErrorHandling: true,
      showLoading: true
    });
  }

  postExternalLinkUser(newUser: NewExternalUser, link?: string): Observable<any> {
    return this.api.post<any>(`${this.apiUrl}/user-creation-link/external/${link}`, newUser, {
      defaultErrorHandling: false,
      showLoading: true
    });
  }
}
