import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { delay, first } from 'rxjs/operators';
import { DownloadLoadingComponent } from '@gal/core/components';

export enum DownloadStatusEnum {
  NOT_STARTED = 1,
  PROCESSING = 2,
  ERROR = 3,
  FINISHED = 4,
  FINISHED_EMPTY = 5
}

@Injectable({
  providedIn: 'root'
})
export class DownloadLoadingService {
  downloadStatusSubject: Subject<DownloadStatusEnum> = new Subject();

  private overlayRef: OverlayRef;
  private showSubscription?: Subscription;
  private hideSubscription?: Subscription;

  constructor(private overlay: Overlay) {
    this.overlayRef = this.overlay.create({
      hasBackdrop: false,
      positionStrategy: this.overlay.position().global()
        .right()
        .bottom()
    });
  }

  show(): void {
    this.downloadStatusSubject.next(DownloadStatusEnum.PROCESSING);
    if (!this.hideSubscription || this.hideSubscription.closed) {
      // Se não existe um evento para esconder, ou o evento já aconteceu, cria um evento para mostrar
      this.showSubscription = this.getEventObservable().subscribe(() => {
        if (!this.overlayRef.hasAttached()) {
          this.overlayRef.attach(new ComponentPortal(DownloadLoadingComponent));
        }
      });
    } else {
      // Se existe um evento para esconder quer dizer que já está sendo mostrado, cancela o evento de esconder
      this.hideSubscription.unsubscribe();
    }
  }

  updateDownloadStatus(status: DownloadStatusEnum) {
    this.downloadStatusSubject.next(status);
  }

  getDownloadStatusObservable(): Observable<DownloadStatusEnum> {
    return this.downloadStatusSubject.asObservable();
  }

  hide(): void {
    if (!this.showSubscription || this.showSubscription.closed) {
      // Se não existe um evento para mostrar, ou o evento já aconteceu, cria um evento para esconder
      this.hideSubscription = this.getEventObservable().subscribe(() => {
        this.overlayRef.detach();
      });
    } else {
      // Se existe um evento para mostrar quer dizer que já está escondido, cancela o evento para mostrar
      this.showSubscription.unsubscribe();
    }
  }

  private getEventObservable(): Observable<any> {
    // Cria um evento que é executado após um delay e cancela a Subscription quando é disparado
    return of(null).pipe(delay(100), first());
  }
}
