import { Component, OnInit } from '@angular/core';
import { AuthService } from '@gal/core/services/auth.service';

@Component({
  selector: 'gal-configure-password',
  templateUrl: './configure-password.component.html',
  styleUrls: ['./configure-password.component.scss']
})
export class ConfigurePasswordComponent implements OnInit {
  constructor(private auth: AuthService) {}

  ngOnInit(): void {
    this.auth.changePassword(true);
  }
}
