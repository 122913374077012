import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'utcDate'
})
export class UtcDatePipe implements PipeTransform {
  withoutTimeValue = 'T00:00:00.000Z';

  transform(value?: string): string {
    return this.getIsoUtcDate(value);
  }

  getIsoUtcDate(value?: string) {
    if (!!value) {
      if (typeof value === 'string' && !(value.indexOf(this.withoutTimeValue))) { // abort if contains time
        return value;
      }

      const currentTimezone = moment().utcOffset(); //receives current time zone in minutes
      const finalDate = moment(value).add(-(currentTimezone), 'minutes'); //add time zone difference by inverting operator signal
      return finalDate.toISOString(); // return the ISOString with date value added from current time zone
    } else {
      return '';
    }
  }
}
