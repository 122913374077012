import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emptyField'
})
export class EmptyFieldPipe implements PipeTransform {
  transform(value: any): any {
    value = typeof value === 'string' ? value.trim() : value;
    if (!value) {
      return '-';
    }
    return value;
  }
}
