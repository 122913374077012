import { Pipe, PipeTransform } from '@angular/core';

interface DocumentArgs {
  onlyCNJP?: boolean;
  onlyCPF?: boolean;
}

@Pipe({
  name: 'document'
})
export class DocumentPipe implements PipeTransform {
  constructor() {}

  transform(value: string, args?: DocumentArgs): any {
    return this.maskDocument(value, args);
  }

  maskDocument(value: string, args?: DocumentArgs): string {
    if (!value) {
      return '';
    }

    value = value.toString();
    const unformatted = value.replace(/\D/g, '');

    if (!!args?.onlyCPF || (unformatted.length < 12 && !args?.onlyCNJP)) {
      return this.maskCpf(value);
    } else if (!!args?.onlyCNJP || (unformatted.length >= 12 && !args?.onlyCPF)) {
      return this.maskCnpj(value);
    } else {
      return '';
    }
  }

  private maskCpf(value: string) {
    value = value.substring(0,11);
    value = value.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca um ponto entre o terceiro e o quarto dígitos
    value = value.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca um ponto entre o terceiro e o quarto dígitos
    // de novo (para o segundo bloco de números)
    value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); // Coloca um hífen entre o terceiro e o quarto dígitos
    return value;
  }

  private maskCnpj(value: string) {
    value = value.substring(0,14);
    value = value.replace(/^(\d{2})(\d)/, '$1.$2'); // Coloca ponto entre o segundo e o terceiro dígitos
    value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); // Coloca ponto entre o quinto e o sexto dígitos
    value = value.replace(/\.(\d{3})(\d)/, '.$1/$2'); // Coloca uma barra entre o oitavo e o nono dígitos
    value = value.replace(/(\d{4})(\d)/, '$1-$2'); // Coloca um hífen depois do bloco de quatro dígitos
    return value;
  }
}
