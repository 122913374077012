import { ICommon } from './environment.model';

export const Common: ICommon = {
  participantApiUrl: '/participants/v1',
  userApiUrl: '/users/v1',
  fundApiUrl: '/funds/v1',
  filesApiUrl: '/files/v1',
  orderApiUrl: '/orders/v1',
  generalParameterApiUrl: '/general-parameters/v1',
  transactionApiUrl: '/transactions/v1'
};
