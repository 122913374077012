import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { DialogConfirmationComponent } from '@gal/shared/components';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

export interface UnsavedDeactivate {
  canDeactivate(): Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class UnsavedGuard implements CanDeactivate<UnsavedDeactivate> {
  canDeactivate(
    component: UnsavedDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return component.canDeactivate
      ? component.canDeactivate().pipe(
        mergeMap(value => {
          if (!!value) {
            return of(value);
          } else {
            const dialog = DialogConfirmationComponent.open({
              title: 'Deseja Realmente Sair?',
              message: 'Os dados não serão salvos após a confirmação'
            });
            return dialog.afterClosed().pipe(map(v => !!v));
          }
        })
      )
      : true;
  }
}
