import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NamePattern } from "@gal/shared/validators";
import { Location } from '@angular/common';
import { NewUserService } from "./services/new-user.service";
import { LinkUserDetail } from "./models/link-user-detail.model";
import { NewExternalUser } from "./models/new-external-user.model";
import { DomainValidator } from "@gal/shared/validators/domain.validator";

@Component({
  selector: 'gal-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {
  id?: string;
  newUser: boolean = false;
  saved: boolean = false;
  expiratedLink: boolean = false;
  userSigninConfirmation: boolean = false;
  loading: boolean = true;
  isUserLinkedToParticipant: boolean = false;

  linkUser?: LinkUserDetail;
  participantName: string = ''

  formUser: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.pattern(/\S+@\S+\.\S+/)]),
    name: new FormControl(null, [Validators.required, Validators.pattern(NamePattern)])
  });

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private newUserService: NewUserService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id') || '';
      if(!!this.id) {
        this.newUserService.getExternalLinkUser(this.id || '').subscribe(result => {
          this.linkUser = result;
          this.expiratedLink = !result.is_active || !!result.is_used;
          this.participantName = result.participant_nickname;

          this.formUser.controls.email.setValidators([
            Validators.required,
            Validators.pattern(/\S+@\S+\.\S+/),
            DomainValidator(result.participant_domains)
          ]);
          this.loading = false;
        });
      }
    });
  }

  signin() {
    this.newUserService.getExternalLinkUser(this.id || '').subscribe(result => {
      this.linkUser = result;
      this.expiratedLink = !result.is_active || !!result.is_used;

      this.formUser.controls.email.setValidators([
        Validators.required,
        Validators.pattern(/\S+@\S+\.\S+/),
        DomainValidator(result.participant_domains)
      ]);
    },
    () => {},
    () => {
      const email = this.formUser.controls.email.value as string;
      const indexOfDomain = email.indexOf('@');
      const domain = email.substring(indexOfDomain);

      this.formUser.updateValueAndValidity();
      this.formUser.markAllAsTouched();

      if(!!this.linkUser?.participant_domains.includes(domain) && this.formUser.valid) {
        this.newUserService.getEmailExist(email, this.linkUser?.participant_id)
          .subscribe((data) => {
            this.newUser = !data.has_email;
            this.isUserLinkedToParticipant = data.has_email_participant;
            this.saved = true;
            if(!!this.newUser || !!this.isUserLinkedToParticipant) {
              this.confirmation();
            }
        });
      }
    });
  }

  cancel() {
    this.location.go('/home');
    window.location.reload();
  }

  confirmation() {
    const newUserConfirmation: NewExternalUser = {
      email: this.formUser.controls.email.value,
      name: this.formUser.controls.name.value,
      codParticipant: this.linkUser?.participant_id || ''
    }

    this.newUserService.postExternalLinkUser(newUserConfirmation, this.linkUser?.id).subscribe(
      (data) => {
        if(!this.isUserLinkedToParticipant) {
          this.userSigninConfirmation = true;
        }
      }
    )
  }
}
