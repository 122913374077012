import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { delay, first } from 'rxjs/operators';
import { FileCreateLoadingComponent } from '../components';
import { FileCreatedLoadingComponent } from '../components/file-created-loading/file-created-loading.component';

export enum FileCreateStatusEnum {
  NOT_CREATED = "Não Criado",
  PROCESSING = "Em processamento",
  CREATED = "Criado",
  ERROR = "Erro na criação"
}

@Injectable({
  providedIn: 'root'
})
export class FileCreateLoadingService {
  fileCreateStatusSubject: Subject<FileCreateStatusEnum> = new Subject();
  fileCreatedStatusSubject: Subject<FileCreateStatusEnum> = new Subject();

  private overlayRef: OverlayRef;
  private showSubscription?: Subscription;
  private hideSubscription?: Subscription;

  constructor(private overlay: Overlay) {
    this.overlayRef = this.overlay.create({
      hasBackdrop: false,
      positionStrategy: this.overlay.position().global()
        .right()
        .bottom()
    });
  }

  show() {
    this.fileCreateStatusSubject.next(FileCreateStatusEnum.PROCESSING);
    if (!this.hideSubscription || this.hideSubscription.closed) {
      // Se não existe um evento para esconder, ou o evento já aconteceu, cria um evento para mostrar
      this.showSubscription = this.getEventObservable().subscribe(() => {
        if (!this.overlayRef.hasAttached()) {
          this.overlayRef.attach(new ComponentPortal(FileCreateLoadingComponent));
        } else {
          this.overlayRef.detach();
          this.overlayRef.attach(new ComponentPortal(FileCreatedLoadingComponent));
        }
      });
    } else {
      // Se existe um evento para esconder quer dizer que já está sendo mostrado, cancela o evento de esconder
      this.hideSubscription.unsubscribe();
    }
  }

  updateFileCreateStatus(status: FileCreateStatusEnum) {
    this.fileCreateStatusSubject.next(status);
  }

  getFileCreateStatusObservable(): Observable<FileCreateStatusEnum> {
    return this.fileCreateStatusSubject.asObservable();
  }

  hide(): void {
    if (!this.showSubscription || this.showSubscription.closed) {
      // Se não existe um evento para mostrar, ou o evento já aconteceu, cria um evento para esconder
      this.hideSubscription = this.getEventObservable().subscribe(() => {
        this.overlayRef.detach();
      });
    } else {
      // Se existe um evento para mostrar quer dizer que já está escondido, cancela o evento para mostrar
      this.showSubscription.unsubscribe();
    }
  }

  private getEventObservable(): Observable<any> {
    // Cria um evento que é executado após um delay e cancela a Subscription quando é disparado
    return of(null).pipe(delay(100), first());
  }
}
