import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';
import { DialogConfirmationComponent } from '@gal/shared/components';
import { fromEvent, merge, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { delay, filter, mergeMap, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IdleService {
  expired$: Subject<boolean> = new Subject<boolean>();
  sessionExpired = false;
  logoutUser = new BroadcastChannel('logoutUser');

  private idle$!: Observable<any>;
  private timer$!: Subscription;
  private timeOutMilliSeconds!: number;
  private warningMessageMilliSeconds!: number;
  private idleSubscription!: Subscription;
  private STORAGE_KEY = '_idleTimeout';
  private warningDialog: MatDialogRef<DialogConfirmationComponent, boolean> | null = null;

  constructor(private msalService: MsalService) {}

  startWatching(timeOutSeconds: number, warningTimeoutSeconds: number = 300): Observable<any> {
    return of(true).pipe(
      delay(Number(localStorage.getItem(this.STORAGE_KEY)) !== 1 ? 10000 : 3000),
      mergeMap(() => {
        this.idle$ = merge(
          fromEvent(document, 'mousemove'),
          fromEvent(document, 'click'),
          fromEvent(document, 'mousedown'),
          fromEvent(document, 'keypress'),
          fromEvent(document, 'DOMMouseScroll'),
          fromEvent(document, 'mousewheel'),
          fromEvent(document, 'touchmove'),
          fromEvent(document, 'MSPointerMove'),
          fromEvent(window, 'mousemove'),
          fromEvent(window, 'resize')
        );
        this.timeOutMilliSeconds = timeOutSeconds * 1000;
        this.warningMessageMilliSeconds = warningTimeoutSeconds * 1000;
        if (!!this.checkExpired()) {
          return this.expired$.pipe(startWith(true));
        } else {
          this.setLastInteraction();
          this.idleSubscription = this.idle$.subscribe(res => this.setLastInteraction());
          this.startTimer();
          return this.expired$;
        }
      })
    );
  }

  setLastInteraction() {
    if (!this.warningDialog) {
      localStorage.setItem(this.STORAGE_KEY, `${Date.now()}`);
    }
  }

  clearInteractions() {
    localStorage.removeItem(this.STORAGE_KEY);
  }

  stopTimer() {
    this.timer$.unsubscribe();
    this.idleSubscription.unsubscribe();
  }

  private startTimer() {
    this.timer$ = timer(0, 1000)
      .pipe(filter(() => !this.sessionExpired))
      .subscribe(() => !!this.checkExpired());
  }

  private setSessionExpired() {
    this.timer$?.unsubscribe();
    this.idleSubscription?.unsubscribe();
    this.sessionExpired = true;
    this.expired$.next(true);
    localStorage.setItem(this.STORAGE_KEY, '1');
    if (this.warningDialog) {
      this.warningDialog.close(false);
    }
  }

  private checkExpired(): boolean {
    const lastInteraction = Number(localStorage.getItem(this.STORAGE_KEY));

    if (lastInteraction > 0 && lastInteraction + this.timeOutMilliSeconds < Date.now() && !this.sessionExpired) {
      this.setSessionExpired();
      return true;
    }

    if (
      lastInteraction > 0 &&
      lastInteraction + (this.timeOutMilliSeconds - this.warningMessageMilliSeconds) < Date.now() &&
      !this.sessionExpired &&
      !!this.msalService.instance.getAllAccounts().length &&
      !this.warningDialog
    ) {
      this.warningDialog = DialogConfirmationComponent.open({
        title: 'Sessão próxima de expirar',
        message: 'Sua sessão irá expirar em 5 minutos.',
        confirmBtn: 'CONTINUAR CONECTADO',
        cancelBtn: 'ENCERRAR'
      });

      this.warningDialog.afterClosed().subscribe(result => {
        if (!!result) {
          localStorage.setItem(this.STORAGE_KEY, `${Date.now()}`);
        } else if (!this.sessionExpired) {
          this.logoutUser.postMessage('Logout User');
        }

        this.warningDialog = null;
      });
    }

    return false;
  }
}
