import { ValidatorFn, AbstractControl } from '@angular/forms';

export const DomainValidator = (validDomains: string[]): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    const email = control.value as string;

    if (typeof email === 'string' && email.includes('@')) {
      const domain = email.substring(email.lastIndexOf('@'));
      if (!validDomains.includes(domain)) {
        return { invalidDomain: true };
      }
    }

    return null;
  };
