import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedInjectorInstance } from '@gal/shared/shared-injector-instance';
import { NamePattern } from '@gal/shared/validators';

interface DialogResponse {
  confirmation: boolean;
  justification?: string;
}

export interface DialogData {
  message: string;
  title?: string;
}

@Component({
  selector: 'gal-dialog-with-confirmation-justification',
  templateUrl: './dialog-with-confirmation-justification.component.html',
  styleUrls: ['./dialog-with-confirmation-justification.component.scss']
})
export class DialogWithConfirmationJustificationComponent {
  justification: FormControl = new FormControl(null, [Validators.required, Validators.pattern(NamePattern)]);

  constructor(
    private dialogRef: MatDialogRef<DialogWithConfirmationJustificationComponent, DialogResponse>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  static open(data?: DialogData): MatDialogRef<DialogWithConfirmationJustificationComponent, DialogResponse> {
    const dialog: MatDialog = SharedInjectorInstance.getInjector().get<MatDialog>(MatDialog);
    return dialog.open(DialogWithConfirmationJustificationComponent, { data });
  }

  confirm() {
    this.dialogRef.close({
      justification: this.justification.value,
      confirmation: true
    });
  }

  cancel(): void {
    this.dialogRef.close({ confirmation: false });
  }
}
