<mat-sidenav-container class="sidenav-container" color="primary">
  <mat-sidenav
    class="sidenav"
    [class.clicked]="clicked"
    [class.expanded]="isExpanded"
    mode="side"
    color="primary"
    [opened]="true"
  >
    <div
      fxLayout="column"
      fxLayoutAlign="center stretch"
      class="sidenav-container-buttons"
    >
      <button
        mat-flat-button
        id="expand-sidenav-button"
        color="primary"
        fxLayout="row"
        fxLayoutAlign="end"
        class="menu_btn"
        [class.expanded]="isExpanded"
        (click)="expandClick()"
        [style.padding-right.px]="0"
      >
        <mat-icon fxFlex fxFlexAlign="center"> double_arrow </mat-icon>
      </button>

      <ng-container
        *ngTemplateOutlet="routesList; context: { $implicit: routes }"
      >
      </ng-container>
    </div>
  </mat-sidenav>
  <mat-sidenav-content
    [class.clicked]="clicked"
    [class.expanded]="isExpanded"
  >
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #routesList let-routes let-deep="deep">
  <ng-container *ngFor="let route of typeRoutes(routes)">
    <ng-container *ngIf="routeItemAccess(route) | async; let routePermitted">
      <ng-container
        *ngIf="
          !!routePermitted && (anySubroutesVisible(route?.subRoutes) | async)
        "
      >
        <div
          class="menu-item"
          [class.expanded]="isExpanded"
          [class.route-active]="anySubrouteChecked(route.subRoutes)"
          fxLayout="row"
          fxLayoutAlign="space-between stretch"
          [routerLinkActive]="
            route.link && route.link !== FAKE_ROUTE ? 'route-active' : ''
          "
        >
          <button
            mat-flat-button
            id="redirect-menu-{{ route.name }}-button"
            color="primary"
            class="route-button"
            [routerLink]="
              !!route.link && route.link !== FAKE_ROUTE ? [route.link] : null
            "
            (click)="
              validateClick(route)
            "
            fxFlex
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <div
              [fxLayout]="isExpanded ? 'row' : 'column'"
              [fxLayoutAlign]="!isExpanded ? 'center center' : ''"
            >
              <mat-icon
                fxFlex
                fxFlexAlign="center"
                [fxFlexOffset]="30 * deep + 'px'"
              >
                {{ route.icon }}
              </mat-icon>
              <div class="route-name" fxFlex fxFlexAlign="center">
                <div [class.closed-menu]="!isExpanded">
                  {{ route?.name }}
                </div>
              </div>
              <ng-container *ngIf="route.link === notificationRoute">
                <ng-container *ngIf="actionNotification$ | async; let action">
                  <ng-container *ngIf="!!action?.total">
                    <div class="action-notification">
                      {{ action.total }}
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </button>
          <button
            mat-flat-button
            *ngIf="
              !!route?.subRoutes?.length &&
              isExpanded &&
              (anySubroutesVisible(route?.subRoutes) | async)
            "
            class="route-button"
            color="primary"
            (click)="toggleSubRoute(route)"
          >
            <mat-icon>
              {{ !!route?.showSubRoutes ? "expand_less" : "expand_more" }}
            </mat-icon>
          </button>
        </div>
        <ng-container
          *ngIf="
            !!route?.subRoutes?.length && !!route?.showSubRoutes && isExpanded
          "
        >
          <ng-container
            *ngTemplateOutlet="
              routesList;
              context: {
                $implicit: route.subRoutes,
                deep: !!deep ? deep + 1 : 1
              }
            "
          >
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>
