import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { PhonePipe } from '@gal/shared/pipes';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[galPhoneMask]'
})
export class PhoneMaskDirective implements OnDestroy, OnInit, OnChanges {
  @Input('galPhoneMask') enabled: boolean | string = true;
  @Input() onlyNumber = false;
  unMask = /\D+/g;

  private subscriber: Subscription | undefined;
  constructor(private elementRef: ElementRef, private model: NgControl, private phonePipe: PhonePipe) {}

  ngOnInit(): void {
    this.subscriber = this.model?.control?.valueChanges.subscribe(() => this.apply());
    this.apply();
  }

  apply(): void {
    const newValue = this.elementRef.nativeElement.value.replace(new RegExp(this.unMask), '');

    this.model?.control?.setValue(newValue, {
      emitEvent: false,
      emitModelToViewChange: false,
      emitViewToModelChange: false
    });

    let format: 'number' | 'ddd' | 'intl' = this.onlyNumber ? 'number' : 'ddd';
    format = this.enabled ? format : 'intl';

    this.elementRef.nativeElement.value = this.phonePipe.transform(newValue, { numberFormat: format });
  }

  ngOnDestroy() {
    this.subscriber?.unsubscribe();
  }
  ngOnChanges() {
    this.apply();
  }
}
