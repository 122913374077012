import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[galDragDropFile]'
})
export class DragDropFileDirective {
  @HostBinding('class.file-over') fileOver = false;
  @Input() disabled = false;
  @Output() fileDrop: EventEmitter<FileList> = new EventEmitter<FileList>();

  @HostListener('dragenter', ['$event']) onDragEnter(evt: DragEvent) {
    evt.preventDefault();
    evt.stopImmediatePropagation();
    if (!this.disabled) {
      this.fileOver = true;
    }
  }

  @HostListener('dragover', ['$event']) onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopImmediatePropagation();
  }

  @HostListener('dragleave', ['$event']) onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopImmediatePropagation();
    if (!this.disabled) {
      this.fileOver = false;
    }
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopImmediatePropagation();
    if (!this.disabled) {
      this.fileOver = false;
      const files = evt.dataTransfer?.files;
      if (!!files && files.length > 0) {
        this.fileDrop.emit(files);
      }
    }
  }
}
