import { Injectable } from '@angular/core';
import { Common } from '@environment';
import { ApiService } from '@gal/core';
import { FundFramingRequest } from '@gal/fund-framing/models/fund-framing-request.model';
import {
  Fund,
  FundList,
  FundParticipant,
  FundParticipantDeleteRequest,
  FundParticipantUpdateRequest,
  FundRequest,
  Parameter
} from '@gal/fund/models';
import { GetFundListParams } from '@gal/fund/models/get-fund-list-params.model';
import { DefaultListParams, RolesEnum } from '@gal/shared/models';
import { retryBackoff } from 'backoff-rxjs';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ClassRequest } from '../models/class-request.model';
import { Class } from '../models/class.model';
import { SubclassRequest } from '../models/subclass-request.model';
import { Subclass } from '../models/subclass.model';
import { FundSingleClassRequest } from '../models/fund-single-class-request.model';
import { FundMultiClassRequest } from '../models/fund-multi-class-request.model';

interface FundParticipantParams {
  document: string;
  role: RolesEnum;
  include_pending?: boolean;
}

interface FundDocumentParams {
  document: string;
  is_shareholder?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FundService {
  textSearchSubject: Subject<string> = new Subject();
  private apiUrl = Common.fundApiUrl;

  private fundParametersSubject: BehaviorSubject<Parameter | null> = new BehaviorSubject<Parameter | null>(null);

  constructor(private api: ApiService) {
    this.getFundParametersCache();
  }

  getFundByID(id: string): Observable<Fund> {
    return this.api.get(`${this.apiUrl}/${id}`, null, {
      defaultErrorHandling: true,
      showLoading: true
    });
  }

  validateFundDocument(document: string): Observable<any> {
    return this.api.get(
      `${this.apiUrl}/validate/document`,
      {
        document
      },
      {
        defaultErrorHandling: true,
        showLoading: true
      }
    );
  }
    validateFundDocumentClassAndNewParentDocument(document: string, oldParentDocument: string, newParentDocument: string): Observable<any> {
      return this.api.get(
        `${this.apiUrl}/validate/document`,
        {
          document: document,
          old_parent_document: oldParentDocument,
          new_parent_document: newParentDocument
        },
        {
          defaultErrorHandling: true,
          showLoading: true
        }
      );
    }

  validateSubclassCvmId(cvmId: string): Observable<any> {
    return this.api.get(
      `${this.apiUrl}/validate/cvmid`,
      {
        cvmId
      },
      {
        defaultErrorHandling: true,
        showLoading: true
      }
    );
  }

  validateFundCodes(sti: string): Observable<any> {
    return this.api.get(
      `${this.apiUrl}/validate/business-code`,
      {
        sti
      },
      {
        defaultErrorHandling: true,
        showLoading: true
      }
    );
  }

  getFundParameters(): Observable<Parameter | null> {
    return this.fundParametersSubject.asObservable();
  }

  getFundParticipants(params: FundParticipantParams, showLoading: boolean = false): Observable<FundParticipant[]> {
    return this.api.get(`${this.apiUrl}/participant`, params, {
      defaultErrorHandling: showLoading,
      showLoading
    })
      .pipe(
        tap(participants => {
          participants.sort((a, b) => (a.nickname && b.nickname ? a.nickname.localeCompare(b.nickname) : 0));
        })
      );
  }

  getFundByDocument(params: FundDocumentParams): Observable<Fund[]> {
    return this.api
      .get(`${this.apiUrl}/document`, params, {
        defaultErrorHandling: false,
        showLoading: false
      })
      .pipe(
        tap(funds => {
          funds.sort((a, b) => (a.nickname && b.nickname ? a.nickname.localeCompare(b.nickname) : 0));
        })
      );
  }

  postFund(body: FundRequest): Observable<Fund> {
    return this.api.post(`${this.apiUrl}/`, body, {
      defaultErrorHandling: true,
      showLoading: true
    });
  }

  getFundList(params: GetFundListParams): Observable<FundList> {
    return this.api.get<FundList>(`${this.apiUrl}/`, params, {
      defaultErrorHandling: true,
      showLoading: true
    });
  }

  putFund(body: FundRequest): Observable<Fund> {
    return this.api.put(`${this.apiUrl}/${body.id}`, body, {
      defaultErrorHandling: true,
      showLoading: true
    });
  }

  putClass(body: Class | Subclass) {
    return this.api.put(`${this.apiUrl}/${body.id}`, body, {
      defaultErrorHandling: true,
      showLoading: true
    });
  }

  updateFundParticipant(idFund: string | null | undefined, body: FundParticipantUpdateRequest): Observable<Fund> {
    return this.api.put(`${this.apiUrl}/${idFund}/participant`, body, {
      defaultErrorHandling: true,
      showLoading: true
    });
  }

  deleteFundParticipant(idFund: string | null | undefined, body: FundParticipantDeleteRequest): Observable<null> {
    return this.api.put(`${this.apiUrl}/${idFund}/remove-future-participant`, body, {
      defaultErrorHandling: true,
      showLoading: true
    });
  }

  rejectParticipantNotification(idFund: string, role: string, denialReason: string): Observable<any> {
    return this.api.put(
      `${Common.fundApiUrl}/denial`,
      { idFund, role, denialReason },
      {
        defaultErrorHandling: true,
        showLoading: true
      }
    );
  }

  confirmParticipantNotification(idFund: string, role: string): Observable<any> {
    return this.api.put(
      `${Common.fundApiUrl}/aware`,
      { idFund, role },
      {
        defaultErrorHandling: true,
        showLoading: true
      }
    );
  }

  getFundFraming(): Observable<any> {
    return this.api.get(`${this.apiUrl}/participant/asset-controller`, null, {
      defaultErrorHandling: true,
      showLoading: true
    });
  }

  updateFundFraming(body: FundFramingRequest): Observable<null> {
    return this.api.put(`${this.apiUrl}/participant/asset-controller`, body, {
      defaultErrorHandling: true,
      showLoading: true
    });
  }

  postClass(parentId: string, body: ClassRequest): Observable<Class> {
    return this.api.post(`${this.apiUrl}/${parentId}/class`, body, {
      defaultErrorHandling: true,
      showLoading: true
    });
  }

  getClass(parentId: string, params: DefaultListParams): Observable<any> {
    return this.api.get<any>(`${this.apiUrl}/${parentId}/class`, params, {
      defaultErrorHandling: true,
      showLoading: true
    });
  }

  postSubclass(parentId: string, body: SubclassRequest): Observable<Subclass> {
    return this.api.post(`${this.apiUrl}/${parentId}/subclass`, body, {
      defaultErrorHandling: true,
      showLoading: true
    });
  }

  getSubclass(parentId: string, params: DefaultListParams): Observable<any> {
    return this.api.get<any>(`${this.apiUrl}/${parentId}/subclass`, params, {
      defaultErrorHandling: true,
      showLoading: true
    });
  }

  postFundSingleClass(body: FundSingleClassRequest): Observable<Fund> {
    return this.api.post(`${this.apiUrl}/parent/single-class`, body, {
      defaultErrorHandling: true,
      showLoading: true
    });
  }

  postFundMultiClass(body: FundMultiClassRequest): Observable<Fund> {
    return this.api.post(`${this.apiUrl}/parent/multiclass`, body, {
      defaultErrorHandling: true,
      showLoading: true
    });
  }

  verifyBrothers(id: string): Observable<boolean> {
    return this.api.get<boolean>(`${this.apiUrl}/has-brothers/${id}`, null, {
      defaultErrorHandling: true,
      showLoading: true
    });
  }

  private getFundParametersCache(): void {
    this.api
      .get<Parameter>(`${this.apiUrl}/parameters`, null, {
        defaultErrorHandling: true,
        showLoading: false
      })
      .pipe(
        retryBackoff({ initialInterval: 250, maxRetries: 5 }),
        tap(parameters => {
          parameters.cvms.sort((a, b) => a.name.localeCompare(b.name));
          parameters.status.sort((a, b) => a.name.localeCompare(b.name));
        })
      )
      .subscribe(parameters => this.fundParametersSubject.next(parameters));
  }
}
