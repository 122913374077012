import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'gal-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit {
  @Input() title = this.activatedRoute.routeConfig?.data?.title;
  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {}
}
