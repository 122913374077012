<div class="background" *ngIf="!loading">
  <!-- Create user card -->
  <div class="card" *ngIf="!saved && !expiratedLink">
    <img height="75" src="/assets/img/border_logo_color.svg" />
    <div class="header"></div>
    <form [formGroup]="formUser" autocomplete="off">
      <div fxLayout="column">
        <mat-form-field id="new-user-email-input-form">
          <mat-label id="new-user-email-input-label"> E-mail </mat-label>
          <input
            id="new-user-email-input"
            matInput
            formControlName="email"
            maxlength="100"
            required
          />
          <mat-error *ngIf="formUser.controls.email.hasError('pattern')">
            * Email inválido
          </mat-error>
          <mat-error *ngIf="formUser.controls.email.hasError('required')">
            * Campo Obrigatório
          </mat-error>
          <mat-error *ngIf="formUser.controls.email.hasError('invalidDomain')">
            * Domínio inválido
          </mat-error>
        </mat-form-field>

        <mat-form-field id="new-user-name-input-form" fxFlex="0 1 390px">
          <mat-label> Nome </mat-label>
          <textarea
            matInput
            id="new-user-name-input"
            matInput
            type="text"
            formControlName="name"
            maxlength="150"
            required
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="10"
            [spellcheck]="false"
            (keydown.enter)="$event.preventDefault()"
          ></textarea>
          <mat-error *ngIf="formUser.controls.name.hasError('pattern')">
            * Nome inválido
          </mat-error>
          <mat-error *ngIf="formUser.controls.name.hasError('required')">
            * Campo Obrigatório
          </mat-error>
        </mat-form-field>
      </div>
    </form>
    <mat-divider></mat-divider>
    <button
      id="sign-in-button"
      class="signin-button"
      mat-flat-button color="accent"
      (click)="signin()">
      Cadastrar
    </button>
  </div>
  <!-- End Create user card -->

  <div class="saved-card" *ngIf="!!saved && !expiratedLink">
    <img height="75" src="/assets/img/border_logo_color.svg" />

    <!-- User of Border PRO -->
    <div *ngIf="!newUser">
      <!-- User Confirmation to participant -->
      <div *ngIf="!userSigninConfirmation && !isUserLinkedToParticipant">
        <div class="saved-header">
          <h2 class="saved-header-title">Usuário já cadastrado na plataforma!</h2>
          <h2 class="saved-header-subtitle">Deseja confirmar a associação do seu usuário a participante {{ participantName }}?</h2>
        </div>
        <div mat-dialog-actions class="confirmation" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="end center">
          <button
            mat-button
            color="primary"
            id="new-user-cancel-button"
            (click)="cancel()"
          >
            Cancelar
          </button>
          <button
            mat-flat-button
            color="accent"
            id="new-user-confirmation-button"
            (click)="confirmation()"
          >
            Confirmar
          </button>
        </div>
      </div>
      <!-- End User Confirmation to participant -->

      <!-- User Association success -->
      <div *ngIf="!!userSigninConfirmation && !isUserLinkedToParticipant">
        <div class="saved-header">
          <h2 class="saved-header-title">Usuário já cadastrado na plataforma!</h2>
          <h2 class="saved-header-subtitle">A associação do seu usuário à Participante {{ participantName }} foi efetuada.</h2>
        </div>
        <div mat-dialog-actions class="confirmation" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="end center">
          <button
            mat-button
            color="primary"
            id="new-user-cancel-button"
            (click)="cancel()"
          >
            OK
          </button>
        </div>
      </div>
      <!-- End User Association success -->

      <!-- User Already Linked -->
      <div *ngIf="!!isUserLinkedToParticipant && !userSigninConfirmation">
        <div class="saved-header" style="margin-top: 50px;">
          <h2 class="saved-header-title">Usuário já existente na participante {{ participantName }}.</h2>
        </div>
        <div mat-dialog-actions class="confirmation" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="end center">
          <button
            mat-button
            color="primary"
            id="new-user-cancel-button"
            (click)="cancel()"
          >
            OK
          </button>
        </div>
      </div>
      <!-- End User Already Linked -->
    </div>
    <!-- End User of Border PRO -->

    <!-- New User of Border PRO -->
    <div *ngIf="!!newUser">
      <div class="saved-header">
        <h2 class="saved-header-title">Cadastro efetuado com sucesso!</h2>
        <h2 class="saved-header-subtitle">Você receberá um e-mail de confirmação e orientação para a ativação do seu login.</h2>
      </div>
      <div mat-dialog-actions class="confirmation" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="end center">
        <button
          mat-button
          color="primary"
          id="new-user-ok-button"
          (click)="cancel()"
        >
          OK
        </button>
      </div>
    </div>
    <!-- End New User of Border PRO -->
  </div>

  <!-- Expirated Link Alert -->
  <div class="saved-card" *ngIf="!!expiratedLink">
    <img height="75" src="/assets/img/border_logo_color.svg" />
    <div *ngIf="!newUser">
      <div class="saved-header">
        <h2 class="saved-header-title">Acesso expirado!</h2>
        <h2 class="saved-header-subtitle">Solicite outro link para realizar o cadastro de usuário.</h2>
      </div>
      <div mat-dialog-actions class="confirmation" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="end center">
        <button
          mat-button
          color="primary"
          id="new-user-ok-button"
          (click)="cancel()"
        >
          OK
        </button>
      </div>
    </div>
  </div>
  <!-- End Expirated Link Alert -->
</div>
