import { Pipe, PipeTransform } from '@angular/core';

interface PhonePipeOptions {
  numberFormat?: 'number' | 'ddd' | 'intl';
}

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(value: any, args?: PhonePipeOptions): any {
    const v = Number(value.replace(/\D/g, ''));
    const vstring = !!v ? v.toString() : '';
    if (args?.numberFormat === 'number') {
      return vstring.replace(/^(\d{3,5})(\d{4})/, '$1-$2');
    } else if (args?.numberFormat === 'ddd') {
      return vstring.replace(/^(\d\d)(\d{3,5})(\d{4})/, '($1) $2-$3');
    } else {
      return vstring;
    }
  }
}
