const specialChar =
  // eslint-disable-next-line max-len
  'A-Za-z\u00C0\u00C1\u00C2\u00C3\u00C4\u00C5\u00C6\u00C7\u00C8\u00C9\u00CA\u00CB\u00CC\u00CD\u00CE\u00CF\u00D0\u00D1\u00D2\u00D3\u00D4\u00D5\u00D6\u00D7\u00D8\u00D9\u00DA\u00DB\u00DC\u00DD\u00DE\u00DF\u00E0\u00E1\u00E2\u00E3\u00E4\u00E5\u00E6\u00E7\u00E8\u00E9\u00EA\u00EB\u00EC\u00ED\u00EE\u00EF\u00F0\u00F1\u00F2\u00F3\u00F4\u00F5\u00F6\u00F8\u00F9\u00FA\u00FB\u00FC\u00FD\u00FE\u00FF';
const nameRegex = `[${specialChar}0-9 @)(_\\-]`;

const allcharacterWord = '.*';

// Name Surname
export const FullNamePattern = `^\\S${allcharacterWord} ${allcharacterWord}\\S$`;

// Name
export const NamePattern = `^\\S${allcharacterWord}\\S$`;

// Nickname
export const NicknamePattern = '^\\S.*[^0]+.*\\S$';

// Stringnospace
export const NoSpacesPattern = '^\\S*$';

export const HourMinutePattern = '^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$';

// Denial Reason between 100 and 999
export const DenialReasonCodePattern = '[1-9][0-9][0-9]';

// Border ID pattern UUID based
export const BorderIdPattern = '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}';

export const IdCvmPattern = '[A-Za-z0-9]+'
