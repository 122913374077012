import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { LoadingComponent, NotFoundComponent } from '@gal/core/components';
import { FlowTypeEnum } from '@gal/core/models/flow-type.enum';
import { ConfigurePasswordComponent } from './core/components/configure-password/configure-password.component';
import { FailedComponent } from './core/components/failed/failed.component';
import { HomeComponent } from './core/components/home/home.component';
import { AuthGuard } from './core/guards/auth.guard';
import { RoutesWithPermission } from './core/models/routes-with-permission.model';
import { RolesEnum } from './shared/models';
import { NewUserComponent } from './core/components/new-user/new-user.component';

const routes: RoutesWithPermission[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {
    path: 'participant',
    canActivate: [MsalGuard, AuthGuard],
    data: {
      guard: {
        canAnyAccess: true
      }
    },
    loadChildren: () => import('./participant/participant.module').then(m => m.ParticipantModule)
  },
  {
    path: 'user',
    canActivate: [MsalGuard, AuthGuard],
    data: {
      guard: {
        canAnyAccess: true
      }
    },
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {
    path: 'fund',
    canActivate: [MsalGuard, AuthGuard],
    data: {
      guard: { canAnyAccess: true }
    },
    loadChildren: () => import('./fund/fund.module').then(m => m.FundModule)
  },
  {
    path: 'fund-distributor',
    canActivate: [MsalGuard, AuthGuard],
    data: {
      guard: {
        canRolesAccess: [
          { flowType: FlowTypeEnum.FOF, roles: [RolesEnum.ADMINISTRADOR, RolesEnum.GESTOR] },
          { flowType: FlowTypeEnum.PCO, roles: [RolesEnum.ADMINISTRADOR, RolesEnum.GESTOR] },
          { flowType: FlowTypeEnum.CC, roles: [RolesEnum.ADMINISTRADOR, RolesEnum.GESTOR] }
        ]
      }
    },
    loadChildren: () => import('./fund-distributor/fund-distributor.module').then(m => m.FundDistributorModule)
  },
  {
    path: 'billeting-time',
    canActivate: [MsalGuard, AuthGuard],
    data: {
      guard: { canAnyAccess: true }
    },
    loadChildren: () => import('./billeting-time/billeting-time.module').then(m => m.BilletingTimeModule)
  },
  {
    path: 'billeting-load',
    canActivate: [MsalGuard, AuthGuard],
    data: {
      guard: {
        canRolesAccess: [
          {
            flowType: FlowTypeEnum.FOF,
            roles: [
              RolesEnum.GESTOR,
              RolesEnum.DISTRIBUIDOR,
              RolesEnum.CONTROLADOR_ATIVO,
              RolesEnum.CONTROLADOR_PASSIVO,
              RolesEnum.CUSTODIANTE
            ]
          },
          {
            flowType: FlowTypeEnum.PCO,
            roles: [RolesEnum.DISTRIBUIDOR, RolesEnum.CONTROLADOR_PASSIVO]
          },
          {
            flowType: FlowTypeEnum.CC,
            roles: [RolesEnum.DISTRIBUIDOR, RolesEnum.CONTROLADOR_PASSIVO]
          }
        ]
      }
    },
    loadChildren: () => import('./billeting-load/billeting-load.module').then(m => m.BilletingLoadModule)
  },
  {
    path: 'order',
    canActivate: [MsalGuard, AuthGuard],
    data: {
      guard: {
        canRolesAccess: [
          {
            flowType: FlowTypeEnum.FOF,
            roles: [
              RolesEnum.GESTOR,
              RolesEnum.DISTRIBUIDOR,
              RolesEnum.ADMINISTRADOR,
              RolesEnum.CONTROLADOR_ATIVO,
              RolesEnum.CONTROLADOR_PASSIVO,
              RolesEnum.CUSTODIANTE
            ]
          },
          {
            flowType: FlowTypeEnum.PCO,
            roles: [
              RolesEnum.GESTOR,
              RolesEnum.DISTRIBUIDOR,
              RolesEnum.ADMINISTRADOR,
              RolesEnum.CONTROLADOR_ATIVO,
              RolesEnum.CONTROLADOR_PASSIVO,
              RolesEnum.CUSTODIANTE
            ]
          },
          {
            flowType: FlowTypeEnum.CC,
            roles: [
              RolesEnum.GESTOR,
              RolesEnum.DISTRIBUIDOR,
              RolesEnum.ADMINISTRADOR,
              RolesEnum.CONTROLADOR_ATIVO,
              RolesEnum.CONTROLADOR_PASSIVO,
              RolesEnum.CUSTODIANTE
            ]
          }
        ]
      }
    },
    loadChildren: () => import('./order/order.module').then(m => m.OrderModule)
  },
  {
    path: 'holiday',
    canActivate: [MsalGuard, AuthGuard],
    data: {
      guard: { canAnyAccess: true }
    },
    loadChildren: () => import('./holiday/holiday.module').then(m => m.HolidayModule)
  },
  {
    path: 'fund-framing',
    canActivate: [MsalGuard, AuthGuard],
    data: {
      guard: {
        canRolesAccess: [
          {
            flowType: FlowTypeEnum.FOF,
            roles: [RolesEnum.CONTROLADOR_ATIVO]
          },
          {
            flowType: FlowTypeEnum.PCO,
            roles: [RolesEnum.ADMINISTRADOR]
          },
          {
            flowType: FlowTypeEnum.CC,
            roles: [RolesEnum.ADMINISTRADOR]
          }
        ]
      }
    },
    loadChildren: () => import('./fund-framing/fund-framing.module').then(m => m.FundFramingModule)
  },
  {
    path: 'action',
    canActivate: [MsalGuard, AuthGuard],
    data: {
      guard: {
        canRolesAccess: [
          {
            flowType: FlowTypeEnum.FOF,
            roles: [
              RolesEnum.GESTOR,
              RolesEnum.DISTRIBUIDOR,
              RolesEnum.ADMINISTRADOR,
              RolesEnum.CONTROLADOR_PASSIVO,
              RolesEnum.CONTROLADOR_ATIVO,
              RolesEnum.CUSTODIANTE
            ]
          },
          {
            flowType: FlowTypeEnum.PCO,
            roles: [RolesEnum.DISTRIBUIDOR, RolesEnum.CONTROLADOR_PASSIVO]
          },
          {
            flowType: FlowTypeEnum.CC,
            roles: [RolesEnum.DISTRIBUIDOR, RolesEnum.CONTROLADOR_PASSIVO]
          }
        ]
      }
    },
    loadChildren: () => import('./action/action.module').then(m => m.ActionModule)
  },
  {
    path: 'denial-reasons',
    canActivate: [MsalGuard, AuthGuard],
    data: {
      guard: { canAnyAccess: true }
    },
    loadChildren: () => import('./denial-reasons/denial-reasons.module').then(m => m.DenialReasonsModule)
  },
  {
    path: 'account',
    canActivate: [MsalGuard, AuthGuard],
    data: {
      guard: {
        canRolesAccess: [
          {
            flowType: FlowTypeEnum.FOF,
            roles: [RolesEnum.GESTOR]
          },
          {
            flowType: FlowTypeEnum.PCO,
            roles: [RolesEnum.GESTOR]
          },
          {
            flowType: FlowTypeEnum.CC,
            roles: [RolesEnum.GESTOR]
          }
        ]
      }
    },
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'order-parameters',
    canActivate: [MsalGuard, AuthGuard],
    data: {
      guard: { canAnyAccess: true }
    },
    loadChildren: () => import('./order-parameters/order-parameters.module').then(m => m.OrderParametersModule)
  },
  {
    path: 'general-parameters',
    canActivate: [MsalGuard, AuthGuard],
    data: {
      guard: { canGalgoAccess: true }
    },
    loadChildren: () => import('./general-parameters/general-parameters.module').then(m => m.GeneralParametersModule)
  },
  {
    path: 'activity-check',
    canActivate: [MsalGuard, AuthGuard],
    data: {
      guard: { canGalgoAccess: true }
    },
    loadChildren: () => import('./activity-check/activity-check.module').then(m => m.ActivityCheckModule)
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'reload',
    component: LoadingComponent
  },
  {
    path: 'notauthorized',
    redirectTo: 'teste',
    pathMatch: 'full'
  },
  {
    path: 'login-failed',
    component: FailedComponent
  },
  {
    path: 'configure-password',
    pathMatch: 'full',
    redirectTo: 'primeiroacesso'
  },
  {
    path: 'primeiroacesso',
    component: ConfigurePasswordComponent
  },
  {
    path: 'new-user/:id',
    component: NewUserComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabled' : 'disabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
