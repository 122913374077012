import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@gal/core/services/auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let requestHeaders = req.headers;

    if (!requestHeaders.has('Content-Type') && !requestHeaders.has('ContentTypeAuto')) {
      requestHeaders = requestHeaders.set('Accept', 'application/json;version=1');
      requestHeaders = requestHeaders.set('Content-Type', 'application/json');
    }

    requestHeaders = requestHeaders.set('Cache-Control', 'no-cache');
    requestHeaders = requestHeaders.set('Ocp-Apim-Trace', 'true');

    //////* Security Headers *//////
    // Doesn't allow the use of frame or iframe
    requestHeaders = requestHeaders.set('X-Frame-Options', 'Deny');
    // Doesn't allow the loading additional scripts and styles in the browser
    requestHeaders = requestHeaders.set('X-Content-Type', 'nosniff');

    //////* NoAuthentication is used when a request doesn't require authentication *//////
    if (!requestHeaders.has('NoAuthentication')) {
      requestHeaders = requestHeaders.set('Flow-Type', this.authService.getSelectedFlowType()?.id);
      requestHeaders = requestHeaders.set('Galgo', this.authService.userInfo?.galgo?.toString() ?? '');
      requestHeaders = requestHeaders.set('Participant', this.authService.getSelectedParticipant()?.id ?? '');
      requestHeaders = requestHeaders.set('Roles', this.authService.getSelectedParticipant()?.user_roles?.join(',') ?? '');
      requestHeaders = requestHeaders.set('User', this.authService.userInfo?.id ?? '');
    }

    req = req.clone({ headers: requestHeaders });
    return next.handle(req);
  }
}
