import { Component, OnDestroy, OnInit } from '@angular/core';
import { FileCreateLoadingService, FileCreateStatusEnum } from '@gal/core/services/file-create-loading.service';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'gal-file-create-loading',
  templateUrl: './file-create-loading.component.html',
  styleUrls: ['./file-create-loading.component.scss']
})
export class FileCreateLoadingComponent implements OnInit, OnDestroy {
  destroySubscriber: Subject<void> = new Subject();
  fileCreateStatus: FileCreateStatusEnum = FileCreateStatusEnum.PROCESSING;
  fileCreateStatusEnum = FileCreateStatusEnum;

  originalUnload: any;
  constructor(private fileCreateLoadingService: FileCreateLoadingService) {}

  ngOnInit(): void {
    this.originalUnload = window.onbeforeunload;
    window.onbeforeunload = (e: any) => {
      e = e || window.event;
      if (e) {
        e.returnValue = 'Download em andamento, tem certeza que deseja sair?';
      }
      return 'Download em andamento, tem certeza que deseja sair?';
    };
    this.fileCreateLoadingService
      .getFileCreateStatusObservable()
      .pipe(
        tap(status => {
          if (status === FileCreateStatusEnum.ERROR) {
            this.fileCreateLoadingService.hide();
          }

          if (status === FileCreateStatusEnum.CREATED) {
            setTimeout(() => {
              this.fileCreateLoadingService.hide();
            }, 5000);
          }
        }),
        takeUntil(this.destroySubscriber)
      )
      .subscribe(complete => {
        this.fileCreateStatus = complete;
      });
  }

  ngOnDestroy(): void {
    window.onbeforeunload = this.originalUnload;
    this.destroySubscriber.next();
    this.destroySubscriber.complete();
  }
}
