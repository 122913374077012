import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@gal/core';
import { AuthGuard } from '@gal/core/guards/auth.guard';
import { ActionNotification } from '@gal/core/models/action-notification.model';
import { FlowTypeEnum } from '@gal/core/models/flow-type.enum';
import { RouteGuardData } from '@gal/core/models/route-guard-data.model';
import { ActionNotificationService } from '@gal/core/services/action-notification.service';
import { RolesEnum } from '@gal/shared/models';
import { Observable, of, Subject, zip } from 'rxjs';
import { filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';

interface RouteItem {
  link: string;
  name: string;
  guardData?: RouteGuardData;
  active: boolean;
  canEnter: boolean;
  icon: string;
  showSubRoutes?: boolean;
  subRoutes: RouteItem[] | null;
}

@Component({
  selector: 'gal-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {
  isExpanded = false;

  clicked = false;

  FAKE_ROUTE = 'fake-route';

  routes: RouteItem[] = [
    {
      link: '/action',
      name: 'Minhas Ações',
      icon: 'ballot',
      guardData: {
        guard: {
          canRolesAccess: [
            {
              flowType: FlowTypeEnum.FOF,
              roles: [
                RolesEnum.GESTOR,
                RolesEnum.DISTRIBUIDOR,
                RolesEnum.ADMINISTRADOR,
                RolesEnum.CONTROLADOR_PASSIVO,
                RolesEnum.CONTROLADOR_ATIVO,
                RolesEnum.CUSTODIANTE
              ]
            },
            {
              flowType: FlowTypeEnum.PCO,
              roles: [RolesEnum.DISTRIBUIDOR, RolesEnum.CONTROLADOR_PASSIVO]
            },
            {
              flowType: FlowTypeEnum.CC,
              roles: [RolesEnum.DISTRIBUIDOR, RolesEnum.CONTROLADOR_PASSIVO]
            }
          ]
        }
      },
      active: false,
      showSubRoutes: false,
      canEnter: true,
      subRoutes: null
    },
    {
      link: '/billeting-load',
      name: 'Histórico de Carga',
      icon: 'pending_actions',
      guardData: {
        guard: {
          canRolesAccess: [
            {
              flowType: FlowTypeEnum.FOF,
              roles: [
                RolesEnum.GESTOR,
                RolesEnum.DISTRIBUIDOR,
                RolesEnum.CONTROLADOR_PASSIVO,
                RolesEnum.CONTROLADOR_ATIVO,
                RolesEnum.CUSTODIANTE
              ]
            },
            {
              flowType: FlowTypeEnum.PCO,
              roles: [RolesEnum.DISTRIBUIDOR, RolesEnum.CONTROLADOR_PASSIVO]
            },
            {
              flowType: FlowTypeEnum.CC,
              roles: [RolesEnum.DISTRIBUIDOR, RolesEnum.CONTROLADOR_PASSIVO]
            }
          ]
        }
      },
      active: false,
      showSubRoutes: false,
      canEnter: true,
      subRoutes: null
    },
    {
      link: '/order',
      name: 'Consultas',
      icon: 'request_quote',
      guardData: {
        guard: {
          canRolesAccess: [
            {
              flowType: FlowTypeEnum.FOF,
              roles: [
                RolesEnum.GESTOR,
                RolesEnum.DISTRIBUIDOR,
                RolesEnum.ADMINISTRADOR,
                RolesEnum.CONTROLADOR_PASSIVO,
                RolesEnum.CONTROLADOR_ATIVO,
                RolesEnum.CUSTODIANTE
              ]
            },
            {
              flowType: FlowTypeEnum.PCO,
              roles: [
                RolesEnum.GESTOR,
                RolesEnum.DISTRIBUIDOR,
                RolesEnum.ADMINISTRADOR,
                RolesEnum.CONTROLADOR_PASSIVO,
                RolesEnum.CONTROLADOR_ATIVO,
                RolesEnum.CUSTODIANTE
              ]
            },
            {
              flowType: FlowTypeEnum.CC,
              roles: [
                RolesEnum.GESTOR,
                RolesEnum.DISTRIBUIDOR,
                RolesEnum.ADMINISTRADOR,
                RolesEnum.CONTROLADOR_PASSIVO,
                RolesEnum.CONTROLADOR_ATIVO,
                RolesEnum.CUSTODIANTE
              ]
            }
          ]
        }
      },
      active: false,
      showSubRoutes: false,
      canEnter: true,
      subRoutes: null
    },
    {
      link: '/account',
      name: 'Gestão de Operação',
      icon: 'paid',
      guardData: {
        guard: {
          canRolesAccess: [
            {
              flowType: FlowTypeEnum.FOF,
              roles: [RolesEnum.GESTOR]
            },
            {
              flowType: FlowTypeEnum.PCO,
              roles: [RolesEnum.GESTOR]
            },
            {
              flowType: FlowTypeEnum.CC,
              roles: [RolesEnum.GESTOR]
            }
          ]
        }
      },
      active: false,
      showSubRoutes: false,
      canEnter: true,
      subRoutes: null
    },
    {
      link: '',
      name: 'Cadastros',
      icon: 'create_new_folder',
      guardData: {
        guard: {
          canAnyAccess: true
        }
      },
      active: false,
      showSubRoutes: true,
      canEnter: true,
      subRoutes: [
        {
          link: '/participant',
          name: 'Participantes',
          icon: 'account_balance',
          guardData: {
            guard: {
              canAnyAccess: true
            }
          },
          active: false,
          showSubRoutes: true,
          canEnter: true,
          subRoutes: null
        },
        {
          link: '/fund',
          name: 'Fundos',
          icon: 'menu_book',
          guardData: {
            guard: {
              canAnyAccess: true
            }
          },
          active: false,
          showSubRoutes: true,
          canEnter: true,
          subRoutes: null
        },
        {
          link: '/fund-distributor',
          name: 'Associação de Distribuidores',
          icon: 'account_tree',
          guardData: {
            guard: {
              canRolesAccess: [
                {
                  flowType: FlowTypeEnum.FOF,
                  roles: [RolesEnum.ADMINISTRADOR, RolesEnum.GESTOR]
                },
                {
                  flowType: FlowTypeEnum.PCO,
                  roles: [RolesEnum.ADMINISTRADOR, RolesEnum.GESTOR]
                },
                {
                  flowType: FlowTypeEnum.CC,
                  roles: [RolesEnum.ADMINISTRADOR, RolesEnum.GESTOR]
                }
              ]
            }
          },
          active: false,
          showSubRoutes: true,
          canEnter: true,
          subRoutes: null
        },
        {
          link: '/fund-framing',
          name: 'Enquadramento Online',
          icon: 'fact_check',
          guardData: {
            guard: {
              canRolesAccess: [
                {
                  flowType: FlowTypeEnum.FOF,
                  roles: [RolesEnum.CONTROLADOR_ATIVO]
                }
              ]
            }
          },
          active: false,
          showSubRoutes: false,
          canEnter: true,
          subRoutes: null
        },
        {
          link: '/user',
          name: 'Usuários',
          icon: 'people',
          guardData: {
            guard: {
              canAnyAccess: true
            }
          },
          active: false,
          showSubRoutes: false,
          canEnter: true,
          subRoutes: null
        }
      ]
    },
    {
      link: '',
      name: 'Parâmetros',
      icon: 'tune',
      guardData: {
        guard: {
          canAnyAccess: true
        }
      },
      active: false,
      showSubRoutes: true,
      canEnter: true,
      subRoutes: [
        {
          link: '/billeting-time',
          name: 'Horário Limite',
          icon: 'schedule',
          guardData: {
            guard: { canAnyAccess: true }
          },
          active: false,
          showSubRoutes: true,
          canEnter: true,
          subRoutes: null
        },
        {
          link: '/holiday',
          name: 'Feriados',
          icon: 'event',
          guardData: {
            guard: {
              canAnyAccess: true
            }
          },
          active: false,
          showSubRoutes: true,
          canEnter: true,
          subRoutes: null
        },
        {
          link: '/denial-reasons',
          name: 'Motivo de Recusa',
          icon: 'sticky_note_2',
          guardData: {
            guard: {
              canAnyAccess: true
            }
          },
          active: false,
          showSubRoutes: true,
          canEnter: true,
          subRoutes: null
        },
        {
          link: '/order-parameters',
          name: 'Config. de Leiaute',
          icon: 'view_column',
          guardData: {
            guard: {
              canAnyAccess: true
            }
          },
          active: false,
          showSubRoutes: true,
          canEnter: true,
          subRoutes: null
        },
        {
          link: '/general-parameters',
          name: 'Gerais',
          icon: 'settings',
          guardData: {
            guard: {
              canGalgoAccess: true
            }
          },
          active: false,
          showSubRoutes: true,
          canEnter: true,
          subRoutes: null
        }
      ]
    },
    {
      link: '',
      name: 'Relatórios',
      icon: 'list_alt',
      guardData: {
        guard: {
          canGalgoAccess: true
        }
      },
      active: false,
      showSubRoutes: true,
      canEnter: true,
      subRoutes: [
        {
          link: '/activity-check',
          name: 'Usuários Logados',
          icon: 'remember_me',
          guardData: {
            guard: { canGalgoAccess: true }
          },
          active: false,
          showSubRoutes: true,
          canEnter: true,
          subRoutes: null
        }
      ]
    }
  ];

  notificationRoute = '/action';
  actionNotification$!: Observable<ActionNotification>;
  destroySubscriber: Subject<void> = new Subject();

  constructor(
    private authGuard: AuthGuard,
    private authService: AuthService,
    private router: Router,
    private actionService: ActionNotificationService
  ) {}

  ngOnInit(): void {
    this.actionNotification$ = this.actionService.getNotificationObservable().pipe(takeUntil(this.destroySubscriber));
  }

  ngOnDestroy(): void {
    this.destroySubscriber?.next();
    this.destroySubscriber?.complete();
  }

  typeRoutes = (r: RouteItem[]): RouteItem[] => r;

  toggleSubRoute = (r: RouteItem) => {
    r.showSubRoutes = !r.showSubRoutes;
    if (!r.showSubRoutes) {
      this.hideSubroutes(r);
    }
  };
  hideAllSubroutes = () => this.routes.forEach(r => this.hideSubroutes(r));

  hideSubroutes(r: RouteItem) {
    r.showSubRoutes = false;
    if (!!r.subRoutes && !!r.subRoutes.length) {
      r.subRoutes.forEach(subRoute => this.hideSubroutes(subRoute));
    }
  }

  public validateClick(route: RouteItem): any {
    return route.link === this.FAKE_ROUTE
      ? this.toggleSubRoute(route)
      : this.getRouteLink(route)
      ? this.expandClick(route)
      : ''
  }

  public getRouteLink(route: any): any {
    let subRoute: any;
    this.routes.forEach(r => {
      if (r.name === "Parâmetros") {
        subRoute = r?.subRoutes?.find(sub => sub.name === 'Config. de Leiaute')?.subRoutes
      }
    });

    if (subRoute?.some((rt: any) => rt.link === route.link)) {
      return route.link
    } else {
      return !route.link
    }
  }

  expandClick(route?: RouteItem): void {
    this.isExpanded = !this.isExpanded;
    this.hideAllSubroutes();
    if (this.isExpanded && route?.subRoutes) {
      this.toggleSubRoute(route);
    }

    this.clicked = true;
  }

  anySubroutesVisible(subroutes: RouteItem[] | null | undefined): Observable<boolean> {
    const obsList = subroutes?.map(r => this.routeItemAccess(r));
    return !!obsList ? zip(...obsList).pipe(map(teste => teste.reduce((a, b) => !!a || !!b))) : of(true);
  }

  anySubrouteChecked(subroutes: RouteItem[] | null | undefined): boolean {
    return !!subroutes
      ?.map(r => (this.router.url.startsWith(r.link) && !!r.link) || (r.link === this.FAKE_ROUTE && this.anySubrouteChecked(r.subRoutes)))
      .reduce((a, b) => a || b);
  }

  routeItemAccess(routeItem: RouteItem): Observable<boolean> {
    return of(true).pipe(
      filter(() => !!this.authService.userInfo && !!this.authService.getSelectedParticipant()),
      mergeMap(() =>
        routeItem.guardData ? this.authGuard.validateRoute(routeItem.guardData).pipe(tap(v => (routeItem.canEnter = v))) : of(true)
      )
    );
  }
}
