import { AlphanumericFormat } from './../utils/alphanumeric-format';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[galAlphanumericOnly]'
})
export class AlphanumericOnlyDirective {
  @Input() includeAccents = false;

  constructor(private e: ElementRef<HTMLInputElement>, private control: NgControl) {}

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initialValue = this.e.nativeElement.value;
    const characters = AlphanumericFormat(initialValue, this.includeAccents);
    this.control?.control?.setValue(characters);

    if (initialValue !== characters) {
      event.stopPropagation();
    }
  }
}
