<div
  class="loading-div mat-elevation-z8"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="8px"
>
  <ng-container *ngIf="downloadStatusEnum.PROCESSING === downloadStatus">
    <mat-spinner
      class="loading"
      color="primary"
      [strokeWidth]="5"
      [diameter]="30"
    >
    </mat-spinner>
    <span> O download iniciará logo. </span>
  </ng-container>

  <ng-container *ngIf="downloadStatusEnum.FINISHED === downloadStatus">
    <mat-icon color="primary" class="success"> check_circle_outline </mat-icon>
    <span> Download concluído. </span>
  </ng-container>

  <ng-container *ngIf="downloadStatusEnum.FINISHED_EMPTY === downloadStatus">
    <mat-icon color="primary"> info </mat-icon>
    <span> Arquivo vazio </span>
  </ng-container>

  <!-- <ng-container *ngIf="downloadStatusEnum.ERROR === downloadStatus">
    <mat-icon color="warn"> cancel_outline </mat-icon>
    <span> Ocorreu um erro ao processar a boleta. </span>
  </ng-container> -->
</div>
