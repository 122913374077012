import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import {
  BreadcrumbComponent,
  FileCreatedLoadingComponent,
  HeaderComponent,
  LoadingComponent,
  NotFoundComponent,
  ShellComponent,
  SidenavComponent
} from '@gal/core/components';
import { RequestInterceptor } from '@gal/core/interceptors/request.interceptor';
import { SharedModule } from '@gal/shared/shared.module';
import { ConfigurePasswordComponent } from './components/configure-password/configure-password.component';
import { DownloadLoadingComponent } from './components/download-loading/download-loading.component';
import { FailedComponent } from './components/failed/failed.component';
import { HomeComponent } from './components/home/home.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { CoreInjectorInstance } from './core-injector-instance';
import { UnsavedGuard } from './guards/unsaved.guard';
import { PaginatorIntlService } from './services/paginator-intl.service';
import { InDevelopmentComponent } from './components/in-development/in-development.component';
import { NewUserComponent } from './components/new-user/new-user.component';
import { FileCreateLoadingComponent } from './components/file-create-loading/file-create-loading.component';

@NgModule({
  declarations: [
    HeaderComponent,
    LoadingComponent,
    ShellComponent,
    SidenavComponent,
    BreadcrumbComponent,
    NotFoundComponent,
    SnackbarComponent,
    FailedComponent,
    DownloadLoadingComponent,
    ConfigurePasswordComponent,
    HomeComponent,
    InDevelopmentComponent,
    NewUserComponent,
    FileCreateLoadingComponent,
    FileCreatedLoadingComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    MatListModule,
    MatSidenavModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    SharedModule
  ],
  exports: [
    LoadingComponent,
    NotFoundComponent,
    DownloadLoadingComponent,
    BreadcrumbComponent,
    InDevelopmentComponent,
    FileCreateLoadingComponent,
    FileCreatedLoadingComponent
  ],
  entryComponents: [
    LoadingComponent,
    DownloadLoadingComponent,
    FileCreateLoadingComponent,
    FileCreatedLoadingComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    { provide: MatPaginatorIntl, useClass: PaginatorIntlService },
    UnsavedGuard
  ]
})
export class CoreModule {
  constructor(private injector: Injector) {
    CoreInjectorInstance.setInjector(this.injector);
  }
}
