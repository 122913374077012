import { IB2CPolicies, IEnvironment } from './environment.model';

export const Environment: IEnvironment = {
  production: false,
  apiUrl: 'https://dev-api.borderpro.com.br'
  // apiUrl: 'https://dev-apim-galgo-001.azure-api.net'
};

export const B2CPolicies: IB2CPolicies = {
  names: {
    signIn: 'B2C_1A_SIGNIN',
    passwordReset: 'B2C_1A_PASSWORDRESET'
  },
  authorities: {
    signIn: {
      authority: 'https://borderprodev.b2clogin.com/borderprodev.onmicrosoft.com/B2C_1A_SIGNIN'
    },
    passwordReset: {
      authority: 'https://borderprodev.b2clogin.com/borderprodev.onmicrosoft.com/B2C_1A_PASSWORDRESET'
    }
  },
  authorityDomain: 'borderprodev.b2clogin.com',
  scopes: ['https://borderprodev.onmicrosoft.com/187ef78f-da5b-4a9c-98b0-3cd1117ada61/Api.All'],
  clientId: '9f46d57f-6ce1-42be-a626-766a25a4f31f'
};
