import { AbstractControl, ValidatorFn } from '@angular/forms';

export const AutocompleteObjectValidator =
  (): ValidatorFn =>
    (control: AbstractControl): { [key: string]: any } | null => {
      if (!!control.value && typeof control.value === 'string') {
        return { autocomplete: { value: control.value } };
      }
      return null; /* valid option selected */
    };
