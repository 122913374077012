<div class="dialog-confirmation">
  <h1 mat-dialog-title [style.width.px]="data.width">{{ data.title }}</h1>
  <div mat-dialog-content [style.width.px]="data.width">
    <div
      class="message"
      [innerHTML]="data.message"
      id="dialog-confirmation-message"
    ></div>
  </div>

  <div mat-dialog-actions [style.width.px]="data.width" class="confirmation" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="end center">
    <button
      *ngIf="!data.isOkBtn"
      mat-button id="dialog-button-no"
      (click)="noClick()"
    >
      {{ data.cancelBtn ?? 'CANCELAR' }}
    </button>
    <button
      *ngIf="!data.isOkBtn"
      mat-button
      color="primary"
      id="dialog-button-yes"
      (click)="yesClick()"
    >
      {{ data.confirmBtn ?? 'CONFIRMAR'  }}
    </button>
    <button
      *ngIf="!!data.isOkBtn"
      mat-button
      color="primary"
      id="dialog-button-ok"
      (click)="noClick()"
    >
      OK
    </button>
  </div>
</div>
