<div class="main-content" fxLayout="column" fxLayoutAlign="center center">
  <div class="not-found-labels" fxLayout="row" fxLayoutAlign="space-evenly">
    <div>4</div>
    <div class="flip">0</div>
    <div>4</div>
  </div>
  <div>
    <h1>404 Page Not Found</h1>
  </div>
</div>
