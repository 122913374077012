<div fxLayout="row" fxLayoutAlign="start center" class="breadcrumb">
  <button
    class="home-button"
    id="home-breadcrumb-button"
    mat-icon-button
    [routerLink]="['/']"
  >
    <mat-icon color="primary" class="material-icons-outlined">home</mat-icon>
  </button>

  <mat-icon class="route-separator">chevron_right</mat-icon>

  <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">
    <a
      class="route-link"
      id="{{ breadcrumb }}-breadcrumb-label"
      [routerLink]="breadcrumb.route"
      [class.last-route]="last"
      [class.ellipsis]="last"
    >
      {{ breadcrumb.label }}
    </a>

    <mat-icon *ngIf="!last" class="route-separator">chevron_right</mat-icon>
  </ng-container>
</div>
