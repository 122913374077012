import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { AuthService, LoadingService } from '@gal/core';
import { FlowTypeEnum } from '@gal/core/models/flow-type.enum';
import { RolesEnum } from '@gal/shared/models';
import * as moment from 'moment';

@Component({
  selector: 'gal-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  isActiveAccount = false;
  isLoading = false;
  broadcastStatus?: InteractionStatus;
  datetimeServer = '';
  welcomeLine = '';

  constructor(
    private auth: AuthService,
    private router: Router,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private loading: LoadingService
  ) {}

  ngOnInit(): void {
    this.setLoading(true);
    this.setIsActiveAccount();
    this.getDatetimeServer();

    this.msalBroadcastService.inProgress$.subscribe((status: InteractionStatus) => {
      this.broadcastStatus = status;

      if (status === InteractionStatus.HandleRedirect || status === InteractionStatus.Logout) {
        this.setLoading(true);
      } else if (status === InteractionStatus.Login) {
        this.setLoading(true, false);
      } else if (status === InteractionStatus.None) {
        this.setIsActiveAccount();
        this.redirectPage();
      }
    });
  }

  redirectToLogin(): void {
    this.isActiveAccount = true;
    this.auth.login();
  }

  private redirectPage(): void {
    if (!!this.isActiveAccount) {
      this.auth.getUserData().subscribe(() => {
        let routeUrl = '';

        if (this.auth.userInfo?.galgo) {
          routeUrl = 'participant/list';
        } else if (
          (this.auth.getSelectedFlowType().id === FlowTypeEnum.FOF &&
            (this.auth.userHasRole(RolesEnum.GESTOR) ||
              this.auth.userHasRole(RolesEnum.ADMINISTRADOR) ||
              this.auth.userHasRole(RolesEnum.CONTROLADOR_ATIVO) ||
              this.auth.userHasRole(RolesEnum.CONTROLADOR_PASSIVO) ||
              this.auth.userHasRole(RolesEnum.CUSTODIANTE) ||
              this.auth.userHasRole(RolesEnum.DISTRIBUIDOR))) ||
          (this.auth.getSelectedFlowType().id === FlowTypeEnum.PCO &&
            (this.auth.userHasRole(RolesEnum.CONTROLADOR_PASSIVO) || this.auth.userHasRole(RolesEnum.DISTRIBUIDOR))) ||
          (this.auth.getSelectedFlowType().id === FlowTypeEnum.CC &&
            (this.auth.userHasRole(RolesEnum.CONTROLADOR_PASSIVO) || this.auth.userHasRole(RolesEnum.DISTRIBUIDOR)))
        ) {
          routeUrl = 'action/tabs';
        } else if (
          this.auth.getSelectedFlowType().id === FlowTypeEnum.PCO ||
          this.auth.getSelectedFlowType().id === FlowTypeEnum.CC
        ) {
          routeUrl = 'participant/list';
        } else {
          routeUrl = 'user/list';
        }

        this.router.navigateByUrl(routeUrl).then(() => {
          this.setLoading(false);
        });
      });
    } else {
      setTimeout(() => this.setLoading(false), 1500);
    }
  }

  private setIsActiveAccount(): void {
    this.isActiveAccount = !!this.msalService.instance.getAllAccounts().length;
  }

  private setLoading(active: boolean, updateLoading: boolean = true): void {
    if (!!updateLoading) {
      this.isLoading = !!active;
    }
    this.loading[!!active ? 'show' : 'hide']();
  }

  private getDatetimeServer(): void {
    this.auth.getDatetimeServer().subscribe(result => {
      this.datetimeServer = result.date;
      const hour = moment(this.datetimeServer).hour();
      if (hour >= 6 && hour < 12) {
        this.welcomeLine = 'Bom Dia, ';
      } else if (hour >= 12 && hour < 18) {
        this.welcomeLine = 'Boa Tarde, ';
      } else if (hour >= 18 || hour < 6) {
        this.welcomeLine = 'Boa Noite, ';
      }
    });
  }
}
