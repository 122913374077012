import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bigDecimal'
})
export class BigDecimalPipe implements PipeTransform {
  transform(value?: string): string {
    return this.showDecimal(value);
  }

  showDecimal(n?: string) {
    if (!!n) {
      const parts = n.split('.');
      return `${parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}${parts[1] ? `,${parts[1].padEnd(8, '0')}` : ''}`;
    } else {
      return '';
    }
  }
}
