<div fxLayout="row" fxLayoutGap="10px" class="stepper-title">
  <div class="circle-step">{{ step }}</div>
  <div fxLayout="column" fxLayoutAlign="center start">
    <div class="title" fxLayout="column" fxLayoutAlign="center stretch">
      {{ title }}
    </div>
    <div class="description" fxLayout="column" fxLayoutAlign="end stretch">
      {{ description }}
    </div>
  </div>
</div>
