<div fxLayout="row" fxLayoutAlign="center center" class="stepper">
  <ng-container
    *ngFor="let step of stepArray; let first = first; let i = index"
  >
    <div
      *ngIf="!first"
      class="line"
      [class.current]="currentStep > i"
    ></div>
    <div
      *ngIf="currentStep"
      class="circle"
      [class.current]="currentStep > i"
    ></div>
  </ng-container>
</div>
