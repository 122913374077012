import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarComponent, SnackbarData } from '@gal/core/components';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  showSuccess(message: string) {
    this.showSnack(message, 'success');
  }

  showError(message = 'Erro inesperado') {
    this.showSnack(message, 'error');
  }

  showInfo(message: string, withoutDuration: boolean = false) {
    this.showSnack(message, 'info', withoutDuration);
  }

  showSnack(message: string, panelClass: 'success' | 'error' | 'info', withoutDuration: boolean = false) {
    const minMilliseconds = 5000;
    const millisecondsRate = 2000;
    const textLengthRate = 10;

    // Every 'textLengthRate' will be applied 'millisecondsRate, with minimum'
    let duration = Math.ceil(message.length / textLengthRate) * millisecondsRate;
    duration = duration < minMilliseconds ? minMilliseconds : duration;

    const config = new MatSnackBarConfig<SnackbarData>();
    config.panelClass = ['gal-snackbar', panelClass];
    config.duration = duration;
    config.horizontalPosition = 'center';
    config.data = {
      html: message,
      action: panelClass
    };

    if (!!withoutDuration) {
      config.duration = undefined;
      config.horizontalPosition = 'end';
    }

    this.snackBar.openFromComponent(SnackbarComponent, config);
  }

  dismiss(): void {
    this.snackBar.dismiss();
  }
}
