import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gal-stepper-header',
  templateUrl: './stepper-header.component.html',
  styleUrls: ['./stepper-header.component.scss']
})
export class StepperHeaderComponent implements OnInit {
  @Input() stepTotal = 3;
  @Input() currentStep = 1;

  stepArray: any[] = [];

  constructor() {}

  ngOnInit(): void {
    this.stepArray = new Array(this.stepTotal);
  }
}
