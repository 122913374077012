import { Component, OnDestroy, OnInit } from '@angular/core';
import { FileCreateLoadingService, FileCreateStatusEnum } from '@gal/core/services/file-create-loading.service';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'gal-file-created-loading',
  templateUrl: './file-created-loading.component.html',
  styleUrls: ['./file-created-loading.component.scss']
})
export class FileCreatedLoadingComponent implements OnInit, OnDestroy {
  destroySubscriber: Subject<void> = new Subject();
  fileCreatedStatus: FileCreateStatusEnum = FileCreateStatusEnum.PROCESSING;
  fileCreatedStatusEnum = FileCreateStatusEnum;

  originalUnload: any;
  constructor(private fileCreateLoadingService: FileCreateLoadingService) {}

  ngOnInit(): void {
    this.fileCreateLoadingService
      .getFileCreateStatusObservable()
      .pipe(
        tap(status => {
          if (status === FileCreateStatusEnum.ERROR) {
            this.fileCreateLoadingService.hide();
          }

          if (status === FileCreateStatusEnum.CREATED) {
            setTimeout(() => {
              this.fileCreateLoadingService.hide();
            }, 5000);
          }
        }),
        takeUntil(this.destroySubscriber)
      )
      .subscribe(complete => {
        this.fileCreatedStatus = complete;
      });

    setTimeout(() => {
      this.closeOvelayRef();
    }, 5000);
  }

  ngOnDestroy(): void {
    window.onbeforeunload = this.originalUnload;
    this.destroySubscriber.next();
    this.destroySubscriber.complete();
  }

  closeOvelayRef() {
    this.fileCreateLoadingService.hide();
  }
}
