import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gal-stepper-title',
  templateUrl: './stepper-title.component.html',
  styleUrls: ['./stepper-title.component.scss']
})
export class StepperTitleComponent implements OnInit {
  @Input() title = '';
  @Input() description = '';
  @Input() step = '';

  constructor() {}

  ngOnInit(): void {}
}
