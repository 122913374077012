import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';

export enum DateCheckEnum {
  Greater = 'greater',
  Less = 'less',
  SameLess = 'sameLess',
  SameGreater = 'sameGreater'
}

export type BooleanFn = () => boolean;

export const DateDifferenceSelectedValidator = (
  dateControl: AbstractControl,
  dateCheck: DateCheckEnum,
  enabled: BooleanFn = () => true
) => {
  let dateToCheck: Moment;
  return (control: AbstractControl): ValidationErrors | null => {
    if (!enabled()) {
      return null;
    }

    dateToCheck = moment(dateControl.value);
    if (!dateControl.value || !dateToCheck || !control.value) {
      return null;
    }

    if (
      (moment(control.value).isBefore(dateToCheck) && dateCheck === DateCheckEnum.Greater) ||
      (moment(control.value).isAfter(dateToCheck) && dateCheck === DateCheckEnum.Less) ||
      (moment(control.value).isBefore(dateToCheck, 'day') && dateCheck === DateCheckEnum.SameGreater) ||
      (moment(control.value).isAfter(dateToCheck, 'day') && dateCheck === DateCheckEnum.SameLess)
    ) {
      return { dateDifference: dateControl.value };
    } else {
      return null;
    }
  };
};
