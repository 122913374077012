import { Injectable } from '@angular/core';
import { Common } from '@environment';
import { ActivityCheckList } from '@gal/activity-check/models/activity-check-list.model';
import { ApiService } from '@gal/core';
import { DefaultListParams } from '@gal/shared/models';
import { Observable, of } from 'rxjs';
import { ActivityCheckRequest } from './../models/activity-check-request.model';

@Injectable({
  providedIn: 'root'
})
export class ActivityCheckService {
  private apiUrl = Common.userApiUrl;
  private apiFullUrl = `${this.apiUrl}/activity-check`;

  constructor(private api: ApiService) {}

  getActivityCheckList(params: DefaultListParams, silent: boolean = false): Observable<ActivityCheckList> {
    return this.api.get<ActivityCheckList>(`${this.apiFullUrl}/list`, params, {
      defaultErrorHandling: !silent,
      showLoading: !silent
    });
  }

  putActivityCheck(): Observable<any> {
    const body: ActivityCheckRequest = {
      path: window.location.pathname
    };

    return this.api.put(this.apiFullUrl, body, {
      defaultErrorHandling: false,
      showLoading: false
    });
  }
}
