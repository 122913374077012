<div class="dialog-confirmation-justification">
  <h1 mat-dialog-title>{{ data.title }}</h1>
  <div [innerText]="data.message"></div>

  <div mat-dialog-content class="dialog-content">
    <div fxLayout="column" fxLayoutGap="16px">
      <div>
        <mat-form-field id="dialog-confirmation-justification-form">
          <mat-label>Justificativa</mat-label>
          <textarea
            matInput
            id="dialog-confirmation-justification-input"
            [formControl]="justification"
            matInput
            type="text"
            minlength="3"
            maxlength="80"
            required
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="10"
            [spellcheck]="false"
            (keydown.enter)="$event.preventDefault()"
          ></textarea>
          <mat-error *ngIf="justification.hasError('required')"> * Campo Obrigatório </mat-error>
          <mat-error *ngIf="justification.invalid"> * Campo Inválido </mat-error>
        </mat-form-field>
      </div>

    </div>
  </div>

  <div
    mat-dialog-actions
    class="confirmation"
    fxLayout="row"
    fxLayoutGap="8px"
    fxLayoutAlign="end center"
  >
    <button
      mat-button
      id="dialog-confirmation-justification-button-cancel"
      (click)="cancel()"
    >
      CANCELAR
    </button>
    <button
      mat-button
      color="primary"
      id="dialog-confirmation-justification-button-confirm"
      (click)="confirm()"
      [disabled]="!justification.valid"
    >
      CONFIRMAR
    </button>
  </div>
</div>
