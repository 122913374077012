<div class="title">
  <div fxLayout="row" fxLayoutAlign="start end" class="page-title-main">
    <h1
      class="ellipsis"
      *ngIf="!!title"
    >
      {{ title }}
    </h1>
    <ng-content select="[detail]"></ng-content>
  </div>
  <div fxLayoutGap="16px" class="page-title-content">
    <ng-content></ng-content>
  </div>
</div>
<mat-divider></mat-divider>
