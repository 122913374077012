import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { DocumentPipe } from '@gal/shared/pipes';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[galDocumentMask]'
})
export class DocumentMaskDirective implements OnInit, OnDestroy, OnChanges {
  @Input('galDocumentMask') enabled: boolean | string = true;
  @Input() onlyCNPJ = false;
  @Input() onlyCPF = false;

  unMask = /\D+/g;
  private subscriber: Subscription | undefined;

  constructor(private elementRef: ElementRef, private model: NgControl, private documentPipe: DocumentPipe) {}

  ngOnInit(): void {
    this.subscriber = this.model?.control?.valueChanges.subscribe(() => {
      this.apply();
    });
    this.apply();
  }

  apply(): void {
    if (!this.enabled && typeof this.enabled === 'boolean') {
      return;
    }
    const newValue = this.elementRef.nativeElement.value.replace(new RegExp(this.unMask), '');
    this.model?.control?.setValue(newValue, {
      emitEvent: false,
      emitModelToViewChange: false,
      emitViewToModelChange: false
    });

    this.elementRef.nativeElement.value = this.documentPipe.transform(newValue, { onlyCNJP: this.onlyCNPJ, onlyCPF: this.onlyCPF });
  }

  ngOnDestroy() {
    this.subscriber?.unsubscribe();
  }
  ngOnChanges(): void {
    this.apply();
  }
}
