import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PreviousUrlService {
  private previousUrl?: string;
  private currentUrl: string;

  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    this.router.events.pipe(filter(event => event instanceof RoutesRecognized)).subscribe(event => {
      this.previousUrl = this.currentUrl;
      sessionStorage.setItem('previousUrl', this.currentUrl);
      this.currentUrl = (event as RoutesRecognized).urlAfterRedirects;
    });
  }

  getPreviousUrl() {
    return sessionStorage.getItem('previousUrl') || this.previousUrl;
  }
}
