import { Injectable } from '@angular/core';
import { Common } from '@environment';
import { ActionNotification } from '@gal/core/models/action-notification.model';
import { Observable, of, ReplaySubject, Subject, timer } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ActionNotificationService {
  apiUrl: string = Common.orderApiUrl;
  startedInterval = false;

  actionNotification$: Subject<ActionNotification> = new ReplaySubject<ActionNotification>();

  constructor(private api: ApiService) {}

  getNotifications(): void {
    if (!this.startedInterval) {
      this.startIntervalAllNotifications();
      this.startedInterval = true;
    }
    this.getAllNotifications().subscribe(action => this.actionNotification$.next(action));
  }

  getNotificationObservable = (): Observable<ActionNotification> => this.actionNotification$.asObservable().pipe(filter(d => !!d));

  private startIntervalAllNotifications(): void {
    timer(0, 60000)
      .pipe(
        mergeMap(() =>
          this.getAllNotifications().pipe(
            catchError(() => of(false)),
            filter(result => !!result)
          )
        )
      )
      .subscribe(action => this.actionNotification$.next(action as ActionNotification));
  }

  private getAllNotifications(): Observable<ActionNotification> {
    return this.api.get<ActionNotification>(`${this.apiUrl}/my-actions/count`, null, {
      defaultErrorHandling: false,
      showLoading: false
    });
  }
}
