import { Injector } from '@angular/core';

export class CoreInjectorInstance {
  private static injectorInstance: Injector;

  static getInjector(): Injector {
    return this.injectorInstance;
  }

  static setInjector(injector?: Injector): void {
    if (injector) {
      this.injectorInstance = injector;
    }
  }
}
