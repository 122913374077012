import { Component, OnDestroy, OnInit } from '@angular/core';
import { DownloadLoadingService, DownloadStatusEnum } from '@gal/core/services/download-loading.service';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'gal-download-loading',
  templateUrl: './download-loading.component.html',
  styleUrls: ['./download-loading.component.scss']
})
export class DownloadLoadingComponent implements OnInit, OnDestroy {
  destroySubscriber: Subject<void> = new Subject();
  downloadStatus: DownloadStatusEnum = DownloadStatusEnum.PROCESSING;
  downloadStatusEnum = DownloadStatusEnum;

  originalUnload: any;
  constructor(private downloadLoadingService: DownloadLoadingService) {}

  ngOnInit(): void {
    this.originalUnload = window.onbeforeunload;
    window.onbeforeunload = (e: any) => {
      e = e || window.event;
      if (e) {
        e.returnValue = 'Download em andamento, tem certeza que deseja sair?';
      }
      return 'Download em andamento, tem certeza que deseja sair?';
    };
    this.downloadLoadingService
      .getDownloadStatusObservable()
      .pipe(
        tap(status => {
          if (status === DownloadStatusEnum.ERROR) {
            this.downloadLoadingService.hide();
          }

          if (status === DownloadStatusEnum.FINISHED || status === DownloadStatusEnum.FINISHED_EMPTY) {
            setTimeout(() => {
              this.downloadLoadingService.hide();
            }, 5000);
          }
        }),
        takeUntil(this.destroySubscriber)
      )
      .subscribe(complete => {
        this.downloadStatus = complete;
      });
  }

  ngOnDestroy(): void {
    window.onbeforeunload = this.originalUnload;
    this.destroySubscriber.next();
    this.destroySubscriber.complete();
  }
}
