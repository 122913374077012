import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localePtExtra from '@angular/common/locales/extra/pt';
import localePt from '@angular/common/locales/pt';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG
} from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { B2CPolicies, Common, Environment } from '@environment';
import { AppRoutingModule } from '@gal/app-routing.module';
import { AppComponent } from '@gal/app.component';
import { CoreModule } from '@gal/core/core.module';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { ErrorInterceptor } from './core/components/error-interceptor/error-interceptor.component';

registerLocaleData(localePt, 'pt', localePtExtra);

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname
  },
  palette: {
    popup: {
      background: '#fff',
      text: '#48484a',
      link: '#46addf'
    },
    button: {
      background: '#6d6e71',
      text: '#fff'
    },
    highlight: {
      background: '#d5f1a2'
    }
  },
  theme: 'classic',
  type: 'info',
  elements: {
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}}
      <a aria-label="saiba mais sobre cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank">
        {{cookiePolicyLink}}
      </a>
    </span>
    `
  },
  content: {
    message: 'Este site usa cookies para garantir que você obtenha uma melhor experiência. ',
    dismiss: 'Entendi!',

    cookiePolicyLink: 'Saiba mais',
    cookiePolicyHref: 'https://www.galgosistemas.com.br/privacidade'
  }
};

export const PathDatetimeNow = `${Common.generalParameterApiUrl}/date/now`;
export const PathExternalLink = `${Common.userApiUrl}/user-creation-link/external/*`;
export const PathEmailExist = `${Common.userApiUrl}/external/*/creation-user-link/exist`;
export const PathPostExternalUser = `${Common.userApiUrl}/external/*/creation-user-link`;

export const LoggerCallback = (logLevel: LogLevel, message: string) => {
  //console.log(message);
};

export const MSALInstanceFactory = (): IPublicClientApplication =>
  new PublicClientApplication({
    auth: {
      clientId: B2CPolicies.clientId,
      authority: B2CPolicies.authorities.signIn.authority,
      knownAuthorities: [B2CPolicies.authorityDomain],
      redirectUri: '/',
      navigateToLoginRequestUrl: true,
      postLogoutRedirectUri: `${ window.location.origin }/home`
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: false
    },
    system: {
      loggerOptions: {
        loggerCallback: LoggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });

export const MSALInterceptorConfigFactory = (): MsalInterceptorConfiguration => {
  const protectedResourceMap = new Map<string, Array<string> | null>();
  protectedResourceMap.set(`${Environment.apiUrl}${PathDatetimeNow}`, null);
  protectedResourceMap.set(`${Environment.apiUrl}${PathExternalLink}`, null);
  protectedResourceMap.set(`${Environment.apiUrl}${PathEmailExist}`, null);
  protectedResourceMap.set(`${Environment.apiUrl}${PathPostExternalUser}`, null);
  protectedResourceMap.set(Environment.apiUrl, B2CPolicies.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
};

export const MSALGuardConfigFactory = (): MsalGuardConfiguration => ({
  interactionType: InteractionType.Redirect,
  authRequest: {
    scopes: B2CPolicies.scopes,
    extraQueryParameters: { ui_locales: 'pt-br' }
  }
});

@NgModule({
  declarations: [AppComponent],
  imports: [NgcCookieConsentModule.forRoot(cookieConfig), BrowserModule, AppRoutingModule, BrowserAnimationsModule, CoreModule, MsalModule],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
